import React, { useCallback, useEffect, useState } from "react";
import "./TabRoles.css";
import {
  addRoles,
  deleteRoles,
  getRoles,
  updateRoles,
} from "../../../services/FirebaseService";
import { RolesItem } from "./data";
import CustomTable, {
  ColumnTypes,
  EditableCell,
  EditableRow,
} from "../../components/CustomTable";
import {
  Badge,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/es/table";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

interface CustomColumnType extends ColumnType<RolesItem> {
  editable?: boolean;
}

const TabRoles = () => {
  const { displayName } = useSelector((state: any) => state.firebase.auth);

  /**
   * @ko-KR 권한 목록
   * */
  const [roles, setRoles] = useState<RolesItem[]>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 권한 목록을 가져오는 함수
   */
  const getRoleList = async () => {
    // API
    const roles = await getRoles();

    setRoles(roles);
    setTableLoader(false);
  };

  // getRoles callback 함수
  const roleListCall = useCallback(async () => await getRoleList(), []);

  useEffect(() => {
    roleListCall();
  }, [roleListCall]);

  const buttonSave = (record: RolesItem) => (
    <Button
      key={"buttonSave"}
      type={"link"}
      style={{ borderWidth: 0 }}
      onClick={() => {
        const isExist =
          roles && roles.filter((role) => role.role === record.role);
        if (isExist && isExist.length > 1)
          message.error("중복된 코드가 존재합니다.");
        else if (record.id) {
          updateRoles(record.id, record).then(() => {
            refreshTable();
          });
        } else {
          addRoles(record).then(() => {
            refreshTable();
          });
        }
      }}
    >
      저장
    </Button>
  );
  const buttonDelete = (record: RolesItem) => (
    <Button
      key={"buttonDelete"}
      type={"link"}
      style={{ borderWidth: 0, color: "red" }}
      onClick={() => {
        setSelectedRole(record);
        setVisible(true);
      }}
    >
      삭제
    </Button>
  );
  const buttonCancel = (record: RolesItem) => (
    <Button
      key={"buttonCancel"}
      type={"link"}
      style={{ borderWidth: 0, color: "red" }}
      onClick={() => {
        const data = roles?.filter((r) => r.key !== record.key);
        setRoles(data);
      }}
    >
      취소
    </Button>
  );

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: CustomColumnType[] = [
    {
      title: "No.",
      dataIndex: "key",
      width: 50,
      render: (_, record) => (
        <span>
          {!record.id && <Badge color={"red"} style={{ marginLeft: 5 }} />}
          {record.id && _}
        </span>
      ),
    },
    {
      title: "권한명",
      dataIndex: "title",
      width: 200,
      editable: true,
    },
    {
      title: "권한코드",
      dataIndex: "role",
      width: 200,
      editable: true,
    },
    {
      title: "Action",
      width: 200,
      render: (_, record) => (
        <>
          {record.id
            ? [buttonSave(record), buttonDelete(record)]
            : [buttonSave(record), buttonCancel(record)]}
        </>
      ),
    },
  ];

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getRoleList();
  };

  /**
   * Editable Table Setting
   */
  const handleAdd = () => {
    const newData: RolesItem = {
      key: (roles?.length || 0) + 1,
      title: "",
      role: "",
      staff_name: displayName,
      created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    setRoles([newData, ...(roles || [])]);
  };

  // input 을 수정 시, 이벤트
  const handleSave = (row: RolesItem) => {
    const isExist = checkExistCode(row.role);
    if (!isExist && roles && roles.length > 0 && row) {
      const roleList = roles.map((role) => (role.key === row.key ? row : role));
      setRoles(roleList);
    }
  };

  const handleDelete = () => {
    setModalLoader(true);
    selectedRole &&
      deleteRoles(selectedRole.id!).then(() => {
        message.success("정상 처리되었습니다.", 1.5);
        handleCancel();
        refreshTable();
      });
  };

  const handleCancel = () => {
    setModalLoader(false);
    setVisible(false);
    setMatched(false);
    setSelectedRole(undefined);
  };

  const checkExistCode = (code: string) => {
    const isExist = roles && roles.filter((role) => role.role === code);
    if (isExist && isExist.length > 1)
      message.error("중복된 코드가 존재합니다.");
    return isExist && isExist.length > 1;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const editableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RolesItem) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  /**
   * 삭제 모달
   */
  const [visible, setVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RolesItem>();
  const [matched, setMatched] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);

  return (
    <>
      <CustomTable
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        columns={editableColumns as ColumnTypes}
        dataSource={roles}
        loading={tableLoader}
        pagination={{ pageSize: 20 }}
        rowKey="key"
        toolbar={{
          onRefresh: () => refreshTable(),
        }}
        toolbarRender={() => [
          <Button
            type={"primary"}
            key={"add-button"}
            style={{
              borderWidth: 0,
              color: "#FFF",
              marginRight: 10,
            }}
            onClick={handleAdd}
            disabled={tableLoader}
          >
            <PlusOutlined /> 권한 추가하기
          </Button>,
        ]}
      />

      {/* 삭제 모달 */}
      <Modal
        visible={visible}
        closable={false}
        maskStyle={{ padding: 0 }}
        onOk={() => handleDelete()}
        onCancel={() => handleCancel()}
        okText="삭제"
        cancelText="취소"
        okButtonProps={{
          disabled: !matched,
        }}
        destroyOnClose={true}
      >
        <Spin tip="잠시 기다려주세요..." spinning={modalLoader}>
          <Typography.Title style={{ fontSize: 20 }}>
            <InfoCircleOutlined style={{ color: "#faad14", marginRight: 10 }} />
            권한을 삭제하시겠습니까?
          </Typography.Title>
          <Typography.Paragraph>
            이 작업을 통해 기존에 등록된 권한들은 모두 제거됩니다.
          </Typography.Paragraph>
          <Divider />
          <Typography.Paragraph>
            권한을 삭제하려면 권한 ID를 입력하세요.{" "}
            <b>{selectedRole && selectedRole.role}</b>
          </Typography.Paragraph>
          <Form.Item
            style={{ margin: 0 }}
            name={"role-title"}
            rules={[
              {
                required: true,
                message: `필수 항목입니다.`,
              },
            ]}
          >
            <Input
              placeholder={selectedRole && selectedRole.role}
              onChange={(e) => {
                if (selectedRole && e.target.value === selectedRole.role)
                  setMatched(true);
                else if (matched) setMatched(false);
              }}
              onPressEnter={() => {
                if (matched) handleDelete();
              }}
              autoComplete="off"
            />
          </Form.Item>
        </Spin>
      </Modal>
    </>
  );
};

export default TabRoles;
