import React, { useCallback, useEffect, useState } from "react";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import CustomTable, { getColumnSearchProps } from "../components/CustomTable";
import { KeepingListItem } from "./data";
import { getCategories, getKeepingItems } from "../../services/FirebaseService";
import { ColumnType } from "antd/es/table";
import {
  deepcopy,
  exportDataToXlsx,
  getDayFilterData,
} from "../../services/Util";
import { Badge, Button, Input } from "antd";
import { FilterConfirmProps } from "antd/es/table/interface";
import { FileExcelOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";

interface CustomKeepingItem extends KeepingListItem {
  created_day: string;
  category_id: string;
  product_name: string;
  product_price: number;
  store_qty: number;
  is_new: boolean;
}

interface CustomColumnType extends ColumnType<CustomKeepingItem> {
  noFilter?: boolean;
}
const Keeping: React.FC = () => {
  /**
   * @ko-KR ME마트 입고 목록
   * */
  const [keepingList, setKeepingList] = useState<CustomKeepingItem[]>();
  // Searched Data
  const [filteredKeepingList, setFilteredKeepingList] =
    useState<CustomKeepingItem[]>();

  /**
   * @ko-KR ME마트 상품 카테고리 목록
   * */
  const [categoryList, setCategoryList] = useState<{ [key: string]: string }>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 카테고리 목록을 가져오는 함수
   */
  const getCategoryList = async () => {
    // API
    const categorySnapshot = await getCategories();

    let categories = {};
    categorySnapshot.docs.forEach((doc) => {
      categories = { ...categories, [doc.id]: doc.data().display_name };
    });

    setCategoryList(categories);
  };

  // getCategories callback 함수
  const categoryListCall = useCallback(async () => await getCategoryList(), []);

  useEffect(() => {
    categoryListCall();
  }, [categoryListCall]);

  /**
   * @ko-KR 검색 관련 설정
   */
  const [searchInput, setSearchInput] = useState<Input | null>(null);
  const [searchText, setSearchText] = useState<string | number | boolean>("");
  const [searchedColumn, setSearchedColumn] = useState<
    string | number | boolean
  >("");

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  /**
   * @ko-KR 상품 목록을 가져오는 함수
   */
  const getKeepingList = async () => {
    // API
    const keepingItems = await getKeepingItems();

    setKeepingList(getTableData(keepingItems));
    setTableLoader(false);
  };

  // getCategories callback 함수
  const keepingListCall = useCallback(async () => await getKeepingList(), []);

  useEffect(() => {
    keepingListCall();
  }, [keepingListCall]);

  useEffect(() => {
    if (categoryList && Object.keys(categoryList).length > 0) {
      getKeepingList();
    }
  }, [categoryList]);

  /**
   * keepingData for Table
   */
  const getTableData = (keepingData: CustomKeepingItem[] | undefined) => {
    if (!keepingData) return [];

    const newList: CustomKeepingItem[] = [];

    keepingData.forEach((data) => {
      const { id, store_items, ...rest } = data;
      const created_day = dayjs(
        typeof data.created_at === "string" ? data.created_at : undefined
      ).format("ddd");

      if (store_items.length > 0) {
        store_items.forEach((item, index) => {
          newList.push({
            ...rest,
            ...item.product_data,
            created_day,
            store_qty: item.store_qty,
            is_new: item.is_new,
            key: id + String(index),
            store_items: [],
          });
        });
      }
    });

    return newList;
  };

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: CustomColumnType[] = [
    {
      title: "입고일시",
      dataIndex: "created_at",
      width: 200,
      render: (text) =>
        searchedColumn === "created_at" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          text
        ),
    },
    {
      title: "요일",
      dataIndex: "created_day",
      width: 80,
      noFilter: true,
      filters: getDayFilterData(),
      onFilter: (value, record) => record.created_day === value,
    },
    {
      title: "담당자명",
      dataIndex: "staff_name",
      render: (text) =>
        searchedColumn === "staff_name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          text
        ),
    },
    {
      title: "상품명",
      dataIndex: "product_name",
      render: (text) =>
        searchedColumn === "product_name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          text
        ),
    },
    {
      title: "카테고리",
      dataIndex: "category_id",
      width: 150,
      noFilter: true,
      render: (text) => categoryList && categoryList[text as string],
      filters:
        categoryList &&
        Object.keys(categoryList).map((key) => ({
          text: categoryList[key],
          value: key,
        })),
      onFilter: (value, record) => record.category_id === value,
    },
    {
      title: "입고가격",
      dataIndex: "product_price",
      noFilter: true,
      render: (text) =>
        `${Number(text)} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "입고수량",
      dataIndex: "store_qty",
      noFilter: true,
      render: (text) => `${text} 개`,
    },
    {
      title: "신상여부",
      dataIndex: "is_new",
      noFilter: true,
      render: (data) => data && <Badge status="error" />,
      filters: [
        {
          text: "O",
          value: true,
        },
        {
          text: "X",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_new === value,
    },
  ];

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getKeepingList();
  };

  /**
   * @ko-KR Excel 다운로드 시, 사용하는 Data
   * */
  const getExcelHeader = () => {
    const mainHeader = deepcopy(columns)
      .map((col: CustomColumnType) => {
        return { title: col.title, dataIndex: col.dataIndex || "" };
      })
      .filter(
        (col: CustomColumnType) => col.title !== "" && col.dataIndex !== ""
      );
    return [...mainHeader];
  };

  const getExcelData = () => {
    if (!keepingList || keepingList.length === 0) return false;

    const targetData = filteredKeepingList || keepingList;

    return targetData.map((prod) => {
      const { key, id, store_items, ...rest } = prod;
      return {
        ...rest,
        is_new: rest.is_new ? "O" : "",
        category_id: categoryList && categoryList[prod.category_id],
      };
    });
  };

  /**
   * @ko-KR Table 컬럼별 검색기능 추가
   * */
  const getFilteredColumn = () => {
    return columns.map((c: CustomColumnType) => {
      const columnSearch =
        c.title && c.dataIndex && !c.noFilter
          ? getColumnSearchProps(
              c.dataIndex as string,
              c.title as string,
              searchInput,
              searchText,
              searchedColumn,
              setSearchInput,
              setSearchText,
              setSearchedColumn,
              handleSearch,
              handleReset,
              true
            )
          : {};
      return { ...c, ...columnSearch };
    });
  };

  return (
    <CustomLayout>
      <CustomContent>
        <CustomTable
          columns={getFilteredColumn()}
          dataSource={keepingList}
          loading={tableLoader}
          pagination={{ pageSize: 20 }}
          rowKey="key"
          toolbar={{
            onRefresh: () => refreshTable(),
          }}
          toolbarRender={() => [
            <Button
              type={"primary"}
              key={"excel-button"}
              style={{
                borderWidth: 0,
                backgroundColor: "#217044",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => {
                const excelData = getExcelData();
                if (excelData)
                  exportDataToXlsx(
                    getExcelHeader(),
                    excelData,
                    "ME마트 입고 목록",
                    "입고 목록",
                    [21, 10, 25, 10, 10, 10, 10]
                  );
              }}
              disabled={!keepingList}
            >
              <FileExcelOutlined /> 엑셀로 내보내기
            </Button>,
          ]}
          onChange={(
            pagination: any,
            filters: any,
            sorter: any,
            extra: { currentDataSource: CustomKeepingItem[] }
          ) => {
            if (extra.currentDataSource) {
              setFilteredKeepingList(extra.currentDataSource);
            }
          }}
        />
      </CustomContent>
    </CustomLayout>
  );
};

export default Keeping;
