export const SET_ROLE = "auth/SET_ROLE";
export const SET_MENU = "auth/SET_MENU";

const INITIAL_STATE = {
  role: "",
  gMenuData: [],
};

export const setRole = (role: string) => ({ type: SET_ROLE, role });
export const setMenu = (data: { key: string; data: string[] }[]) => ({
  type: SET_MENU,
  gMenuData: data,
});

//reducer
export default function (state = INITIAL_STATE, action: any) {
  if (action.type === SET_ROLE) {
    return { ...state, role: action.role || "new" };
  } else if (action.type === SET_MENU) {
    return { ...state, gMenuData: action.gMenuData || [] };
  } else {
    return state;
  }
}
