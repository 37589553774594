import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Switch, useHistory } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { useDispatch } from "react-redux";

import PrivateRoute from "./Routes/components/PrivateRoutes";
import Home from "./Routes/Home";
import SignIn from "./Routes/SignIn";
import { SET_ROLE } from "./ducks/authReducer";
import { getUserRole } from "./services/FirebaseService";
import { Spin } from "antd";

//dayjs
import "dayjs/locale/ko";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.locale("ko");
dayjs.extend(isBetween);

function App() {
  const [authReady, setAuthReady] = useState(false);
  const firebase = useFirebase();
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * 세션에 로그인 된 사용자가 있다면
   * 해당 데이터로 로그인
   */
  useEffect(() => {
    // 현재 주소
    const current_path = window.location.pathname;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        getUserRole(user.uid).then((role: string) => {
          // 사용자 권한 지정해주기
          dispatch({ type: SET_ROLE, role: role || "new" });
          // View 활성화
          setAuthReady(true);

          /**
           * '', '/', '/login' 의 경우에만 /index 로 이동
           * 특정 주소가 있다면, 해당 주소로 이동
           */
          history.push(
            ["", "/", "/login"].indexOf(current_path) > -1
              ? "/index"
              : current_path
          );
        });
      } else {
        // View 활성화
        setAuthReady(true);
      }
    });
  }, []);

  return (
    <div
      className="App"
      style={
        !authReady
          ? {
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {}
      }
    >
      {authReady ? (
        <Switch>
          <PrivateRoute path="/index">
            <Home />
          </PrivateRoute>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
      ) : (
        <Spin size="large" tip="사용자 정보를 확인하는 중입니다" />
      )}
    </div>
  );
}

export default App;
