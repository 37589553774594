import React, { useEffect } from "react";
import { Layout, PageHeader } from "antd";
import { pageList } from "../../Routes/components/MenuList";

type CustomLayoutProps = {
  children: JSX.Element | JSX.Element[];
};
const CustomLayout = ({ children }: CustomLayoutProps) => {
  const currentPath = window.location.pathname.replace("/index", "");
  const title = pageList.find((p) => p.path === currentPath)?.title;

  useEffect(() => {
    document.title = `ME마트 관리자 - ${title}` || "ME마트 관리자";
  }, [title]);

  return (
    <>
      <PageHeader title={title} className="site-page-header" />
      {children}
    </>
  );
};

const { Content } = Layout;
export const CustomContent = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <Content
      className="site-layout-background"
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 280,
      }}
    >
      {children}
    </Content>
  );
};

export default CustomLayout;
