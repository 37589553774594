import React, { useEffect } from "react";
import "./SignIn.css";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { signInWithGoogle } from "../services/FirebaseService";
import { Button, Col, Image, Row } from "antd";

import loginImage from "../assets/login.svg";
import loginBackground from "../assets/login_background.svg";

const SignIn = () => {
  const firebase = useFirebase();
  const history = useHistory();

  useEffect(() => {
    document.title = "ME마트 관리자 - 로그인";
  }, []);

  return (
    <Row
      className="signin-row"
      style={{ backgroundImage: `url(${loginBackground})` }}
    >
      <Col
        span={12}
        className="signin-col"
        style={{ justifyContent: "flex-end" }}
      >
        <Image
          src={loginImage}
          preview={false}
          wrapperStyle={{ width: "60%" }}
        />
      </Col>
      <Col
        span={12}
        className="signin-col"
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          paddingLeft: 40,
        }}
      >
        <h1 style={{ fontSize: 30, textAlign: "left", lineHeight: 1.3 }}>
          로그인하고
          <br />
          마트관리를 시작해보세요
        </h1>
        <Button
          type={"primary"}
          onClick={() => {
            signInWithGoogle(firebase, () => history.push("/index"));
          }}
        >
          Sign In with Google
        </Button>
      </Col>
    </Row>
  );
};

export default SignIn;
