import React, { useCallback, useEffect, useState } from "react";
import "./SalesPage.css";
import { OrderListItem } from "./data";
import { Button, Input, Tag } from "antd";
import { ColumnType } from "antd/es/table";
import {
  deepcopy,
  exportDataToXlsx,
  getAgeGroup,
  getDayFilterData,
} from "../../services/Util";
import { UserListItem } from "../Users/data";
import { getCategories, getOrders } from "../../services/FirebaseService";
import dayjs from "dayjs";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import CustomTable, { getColumnSearchProps } from "../components/CustomTable";
import { FileExcelOutlined } from "@ant-design/icons";
import { ProductListItem } from "../Products/data";
import firebase from "firebase";
import { FilterConfirmProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";

const PAYMENT_TYPE: { [key: string]: string } = {
  point: "적립금 결제",
  card: "카드 결제",
  recharge: "적립금 충전",
};

const PAYMENT_TYPE_COLOR: { [key: string]: string } = {
  point: "green",
  card: "blue",
  recharge: "purple",
};

interface SalesProps extends OrderListItem {
  id?: string;
  key?: React.Key;
  category_id: string;
  product_barcode: string;
  product_name: string;
  product_price: number;
  product_price_qty: number;
  product_stock: number;
  product_type?: string;
  hidden?: boolean;
  cart_qty?: number;
  image_uri?: string;
  user_data: UserListItem;
  order_items: ProductListItem[];
  payment_type: "recharge" | "card" | "point";
  total_price: number;
  receipt_id: string;
  receipt_uri: string;
  created_at: firebase.firestore.Timestamp;
  display_user_name?: string;
  display_payment_type?: string;
  class_name?: boolean;
  created_day?: string;
}

interface CustomColumnType extends ColumnType<SalesProps> {
  noFilter?: boolean;
}

const SalesPage: React.FC = () => {
  /**
   * @ko-KR ME마트 결제 목록
   * */
  const [orderList, setOrderList] = useState<SalesProps[]>();
  // Searched Data
  const [filteredSalesList, setFilteredSalesList] = useState<SalesProps[]>();

  /**
   * @ko-KR ME마트 상품 카테고리 목록
   * */
  const [categoryList, setCategoryList] = useState<{ [key: string]: string }>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 카테고리 목록을 가져오는 함수
   */
  const getCategoryList = async () => {
    // API
    const categorySnapshot = await getCategories();

    let categories = {};
    categorySnapshot.docs.forEach((doc) => {
      categories = { ...categories, [doc.id]: doc.data().display_name };
    });

    setCategoryList(categories);
  };

  // getCategories callback 함수
  const categoryListCall = useCallback(async () => await getCategoryList(), []);

  useEffect(() => {
    categoryListCall();
  }, [categoryListCall]);

  /**
   * @ko-KR 결제방식 별 태그 설정
   */
  const getPaymentTypeTag = (type: string) => {
    const name = PAYMENT_TYPE[type];
    return (
      <Tag color={PAYMENT_TYPE_COLOR[type]} key={name}>
        {name}
      </Tag>
    );
  };

  /**
   * @ko-KR 검색 관련 설정
   */
  const [searchInput, setSearchInput] = useState<Input | null>(null);
  const [searchText, setSearchText] = useState<string | number | boolean>("");
  const [searchedColumn, setSearchedColumn] = useState<
    string | number | boolean
  >("");

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: CustomColumnType[] = [
    {
      title: "결제일시",
      dataIndex: "created_at",
      render: (text, record, index) =>
        searchedColumn === "created_at" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          <span>{text}</span>
        ),
      width: 180,
    },
    {
      title: "요일",
      dataIndex: "created_day",
      width: 80,
      noFilter: true,
      filters: getDayFilterData(),
      onFilter: (value, record) => record.created_day === value,
    },
    {
      title: "상품명",
      dataIndex: "product_name",
      width: 300,
      render: (text) =>
        searchedColumn === "product_name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          text
        ),
    },
    {
      title: "카테고리",
      dataIndex: "category_id",
      width: 150,
      noFilter: true,
      render: (text) => categoryList && categoryList[text as string],
      filters:
        categoryList &&
        Object.keys(categoryList).map((key) => ({
          text: categoryList[key],
          value: key,
        })),
      onFilter: (value, record) => record.category_id === value,
    },
    {
      title: "상품가격",
      dataIndex: "product_price",
      width: 150,
      noFilter: true,
      render: (text) =>
        `${Number(text)} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "구매수량",
      dataIndex: "cart_qty",
      width: 120,
      noFilter: true,
      render: (text) => `${text} 개`,
    },
    {
      title: "결제금액",
      dataIndex: "product_price_qty",
      width: 120,
      noFilter: true,
      render: (text) =>
        `${Number(text)} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    {
      title: "결제방식",
      dataIndex: "payment_type",
      noFilter: true,
      render: (text) => getPaymentTypeTag(text as string),
      filters: Object.keys(PAYMENT_TYPE).map((key) => ({
        text: PAYMENT_TYPE[key],
        value: key,
      })),
      onFilter: (value, record) => record.payment_type === value,
    },
    {
      title: "결제자명",
      dataIndex: "display_user_name",
      width: 120,
      render: (text) =>
        searchedColumn === "display_user_name" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? String(text) : ""}
          />
        ) : (
          text
        ),
    },
    {
      title: "연령대",
      dataIndex: "display_user_age",
      render: (text) =>
        searchedColumn === "display_user_age" ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText as string]}
            autoEscape
            textToHighlight={text ? `${text} 대` : ""}
          />
        ) : (
          `${text} 대`
        ),
      width: 120,
    },
  ];

  /**
   * @ko-KR Table 컬럼별 검색기능 추가
   * */
  const getFilteredColumn = () => {
    return columns.map((c: CustomColumnType) => {
      const columnSearch =
        c.title && c.dataIndex && !c.noFilter
          ? getColumnSearchProps(
              c.dataIndex as string,
              c.title as string,
              searchInput,
              searchText,
              searchedColumn,
              setSearchInput,
              setSearchText,
              setSearchedColumn,
              handleSearch,
              handleReset,
              true
            )
          : {};
      return { ...c, ...columnSearch };
    });
  };

  /**
   * orderData for Table
   */
  const getTableData = (orderData: OrderListItem[] | undefined) => {
    if (!orderData) return [];

    const newList: SalesProps[] = [];

    orderData.forEach((order) => {
      const { id, order_items, ...rest } = order;

      if (order_items.length > 0) {
        order_items.forEach((item: ProductListItem, index) => {
          newList.push({
            ...rest,
            ...item,
            key: id + String(index),
            product_price_qty: item.product_price * (item.cart_qty || 0),
            order_items: [],
          });
        });
      } else if (rest.payment_type === "recharge") {
        newList.push({
          ...rest,
          product_name: "적립금 충전",
          product_price: rest.total_price,
          product_barcode: "",
          product_stock: 0,
          key: id,
          product_price_qty: rest.total_price,
          cart_qty: 1,
          category_id: "01",
          order_items: [],
        });
      }
    });

    return newList;
  };

  /**
   * @ko-KR 상품 목록을 가져오는 함수
   */
  const getOrderList = async () => {
    // API
    const orders = await getOrders();

    /**
     * 검색에 가공된 테이블 데이터가 걸리기 위해
     * 데이터 가공 작업을 진행
     */
    const searchableOrders = orders.map((order, index) => {
      const birth = order.user_data.user_birth;

      return {
        ...order,
        created_day: dayjs(order.created_at).format("ddd"),
        display_user_age: getAgeGroup(birth),
        display_user_name: order.user_data.user_name, // 결제자명
        display_payment_type: PAYMENT_TYPE[order.payment_type], // 결제방식
      };
    });

    setOrderList(getTableData(searchableOrders));
    setTableLoader(false);
  };

  // getCategories callback 함수
  const orderListCall = useCallback(async () => await getOrderList(), []);

  useEffect(() => {
    orderListCall();
  }, [orderListCall]);

  useEffect(() => {
    if (categoryList && Object.keys(categoryList).length > 0) {
      getOrderList();
    }
  }, [categoryList]);

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getOrderList();
  };

  /**
   * @ko-KR Excel 다운로드 시, 사용하는 Data
   * */
  const getExcelHeader = () => {
    const mainHeader = deepcopy(columns)
      .map((col: ColumnType<OrderListItem>) => {
        return { title: col.title, dataIndex: col.dataIndex || "" };
      })
      .filter(
        (col: ColumnType<OrderListItem>) =>
          col.title !== "" && col.dataIndex !== ""
      );
    return [...mainHeader];
  };

  const getExcelData = () => {
    if (!orderList || orderList.length === 0) return false;

    const targetData = filteredSalesList || orderList;

    return targetData.map((order) => {
      const { key, id, display_payment_type, ...rest } = order;
      return {
        ...rest,
        payment_type: display_payment_type,
        category_id: categoryList && categoryList[order.category_id],
      };
    });
  };

  return (
    <CustomLayout>
      <CustomContent>
        <CustomTable
          columns={getFilteredColumn()}
          dataSource={orderList}
          loading={tableLoader}
          pagination={{ pageSize: 20 }}
          rowKey="key"
          toolbar={{
            onRefresh: () => refreshTable(),
          }}
          toolbarRender={() => [
            <Button
              type={"primary"}
              key={"excel-button"}
              style={{
                borderWidth: 0,
                backgroundColor: "#217044",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => {
                const excelData = getExcelData();
                if (excelData)
                  exportDataToXlsx(
                    getExcelHeader(),
                    excelData,
                    "ME마트 판매 현황",
                    "판매 현황",
                    [21, 6, 25, 10, 10, 10, 11, 10, 10]
                  );
              }}
              disabled={!orderList}
            >
              <FileExcelOutlined /> 엑셀로 내보내기
            </Button>,
          ]}
          onChange={(
            pagination: any,
            filters: any,
            sorter: any,
            extra: { currentDataSource: SalesProps[] }
          ) => {
            if (extra.currentDataSource) {
              setFilteredSalesList(extra.currentDataSource);
            }
          }}
        />
      </CustomContent>
    </CustomLayout>
  );
};

export default SalesPage;
