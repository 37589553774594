import React from "react";
import { Image, Layout, Typography } from "antd";

import Image404 from "../../assets/404_error.svg";
import Image401 from "../../assets/unauthorized.svg";
import { useSelector } from "react-redux";

const { Content } = Layout;
const ErrorCase = [
  {
    code: "404",
    image: Image404,
    title: "존재하지 않는 페이지입니다.",
    subtitle: "주소가 올바른지 확인해주세요.",
  },
  {
    code: "401",
    image: Image401,
    title: "접근권한이 없는 페이지를 요청하였습니다.",
    subtitle: "권한을 얻으려면 관리자에게 문의해주세요.",
  },
];

type ErrorPageProps = {
  errorCode: "404" | "401";
};
const ErrorPage = ({ errorCode }: ErrorPageProps) => {
  const data = ErrorCase.find((e) => e.code === errorCode);
  const { role } = useSelector((state: any) => state.authReducer);

  return (
    <Content
      style={{
        display: "flex",
        height: "100vh",
        minHeight: 600,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {data && (
        <>
          <Image src={data.image} preview={false} height={250} />
          <Typography.Title style={{ marginTop: 30 }}>
            {data.title}
          </Typography.Title>
          <Typography.Text style={{ fontSize: 20 }}>
            {data.subtitle}
          </Typography.Text>
          {data.code === "401" && (
            <Typography.Text style={{ fontSize: 20 }}>
              회원님의 권한은 '{role}' 입니다.
            </Typography.Text>
          )}
        </>
      )}
    </Content>
  );
};

export default ErrorPage;
