import React, { useCallback, useEffect, useState } from "react";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import { OrderListItem } from "../Sales/data";
import { UserListItem } from "../Users/data";
import { ProductListItem } from "../Products/data";
import { getCategories, getOrders } from "../../services/FirebaseService";
import dayjs from "dayjs";
import { commaNumber, getAgeGroup, isPositive } from "../../services/Util";
import {
  Button,
  Card,
  Col,
  List,
  Radio,
  Row,
  Skeleton,
  Space,
  Statistic,
  Tooltip,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";

import DatePicker from "../components/DatePicker";
import "dayjs/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import {
  Datum,
  DualAxes,
  DualAxesConfig,
  Line,
  LineConfig,
  Pie,
  PieConfig,
} from "@ant-design/charts";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { RangeValue } from "rc-picker/lib/interface";
import { PREFIX_PATH } from "../../Routes/components/MenuList";

const PAYMENT_TYPE: { [key: string]: string } = {
  point: "적립금 결제",
  card: "카드 결제",
  recharge: "적립금 충전",
};

interface SalesProps extends OrderListItem {
  id?: string;
  key?: React.Key;
  category_id: string;
  product_id: string;
  product_barcode: string;
  product_name: string;
  product_price: number;
  product_price_qty: number;
  product_stock: number;
  product_type?: string;
  hidden?: boolean;
  cart_qty?: number;
  image_uri?: string;
  user_data: UserListItem;
  order_items: ProductListItem[];
  payment_type: "recharge" | "card" | "point";
  total_price: number;
  receipt_id: string;
  receipt_uri: string;
  created_at: string;
  display_user_name?: string;
  display_payment_type?: string;
  class_name?: boolean;
  created_day?: string;
}

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 24 },
};

/***
 * 마감 현황판 구성
 *
 * - 탭은 크게 '전체'/'기간별' 로 구분하여 적용
 * - 각 탭의 항목에는
 *  ㄴ 상품별 판매 순위 5위
 *  ㄴ 카테고리별 판매 순위 5위
 *  ㄴ 연령별 판매 비율
 *  ㄴ 결제방식 비율
 *  ㄴ 판매수량 추이
 *  ㄴ 총 매출금액 / 평균 금액
 *  ㄴ 요일별 판매 비율
 */
const Closing: React.FC = () => {
  /**
   * @ko-KR ME마트 판매 현황 (기준 데이터)
   * */
  const [salesList, setSalesList] = useState<SalesProps[]>();

  /**
   * @ko-KR 기간별 현황
   * */
  const [range, setRange] = useState<RangeValue<dayjs.Dayjs>>();
  const [rangeSalesList, setRangeSalesList] = useState<SalesProps[]>();

  /**
   * @ko-KR ME마트 상품 카테고리 목록
   * */
  const [categoryList, setCategoryList] = useState<{ [key: string]: string }>();

  /**
   * @ko-KR 카테고리 목록을 가져오는 함수
   */
  const getCategoryList = async () => {
    // API
    const categorySnapshot = await getCategories();

    let categories = {};
    categorySnapshot.docs.forEach((doc) => {
      categories = { ...categories, [doc.id]: doc.data().display_name };
    });

    setCategoryList(categories);
  };

  // getCategories callback 함수
  const categoryListCall = useCallback(async () => await getCategoryList(), []);

  useEffect(() => {
    categoryListCall();
  }, [categoryListCall]);

  /**
   * orderData for Table
   */
  const getTableData = (orderData: OrderListItem[] | undefined) => {
    if (!orderData) return [];

    const newList: SalesProps[] = [];

    orderData.forEach((order) => {
      const { id, order_items, ...rest } = order;

      if (order_items.length > 0) {
        order_items.forEach((item: ProductListItem, index) => {
          newList.push({
            ...rest,
            ...item,
            product_id: item.id || "",
            key: id + String(index),
            product_price_qty: item.product_price * (item.cart_qty || 0),
            order_items: [],
          });
        });
      } else if (rest.payment_type === "recharge") {
        newList.push({
          ...rest,
          product_id: "",
          product_name: "적립금 충전",
          product_price: rest.total_price,
          product_barcode: "",
          product_stock: 0,
          key: id,
          product_price_qty: rest.total_price,
          cart_qty: 1,
          category_id: "01",
          order_items: [],
        });
      }
    });

    return newList;
  };

  /**
   * @ko-KR 상품 목록을 가져오는 함수
   */
  const getOrderList = async () => {
    // API
    const orders = await getOrders();

    /**
     * 검색에 가공된 테이블 데이터가 걸리기 위해
     * 데이터 가공 작업을 진행
     */
    const searchableOrders = orders.map((order, index) => {
      const birth = order.user_data.user_birth;

      return {
        ...order,
        created_day: dayjs(order.created_at).format("ddd"),
        display_user_age: getAgeGroup(birth),
        display_user_name: order.user_data.user_name, // 결제자명
        display_payment_type: PAYMENT_TYPE[order.payment_type], // 결제방식
      };
    });

    setSalesList(getTableData(searchableOrders));
  };

  useEffect(() => {
    if (categoryList && Object.keys(categoryList).length > 0) {
      getOrderList();
    }
  }, [categoryList]);

  /**
   * @ko-KR 보여줄 데이터의 기준
   */
  const [targetType, setTargetType] = useState<"all" | "range">("all");
  const handleTargetChange = (e: RadioChangeEvent) => {
    if (e.target.value === "all") {
      setRangeSalesList(undefined);
      setRange(undefined);
    }
    setTargetType(e.target.value);
  };

  useEffect(() => {
    if (!range || !salesList) return;

    // Format 변경
    const startDate = range[0]!.format("YYYY-MM-DD");
    const endDate = range[1]!.add(1, "day").format("YYYY-MM-DD");

    const rangeList = salesList.filter(
      (item) =>
        item.created_at &&
        dayjs(item.created_at).isBetween(startDate, endDate, null, "()")
    );

    setRangeSalesList(rangeList);
  }, [range]);

  /*********************
   *
   * 데이터 가공 영역 시작
   *
   *********************/

  /**
   * PieChart Config
   */
  const getPieChartConfig = (angleField: string, colorField: string) =>
    ({
      appendPadding: 10,
      data: [],
      angleField,
      colorField,
      radius: 0.9,
      label: {
        type: "inner",
        offset: "-50%",
        content: (_ref) => {
          const percent = _ref.percent;
          if (percent < 0.05) return "";
          return "".concat((percent * 100).toFixed(0), "%");
        },
        style: {
          fontSize: 14,
          textAlign: "center",
        },
      },
      interactions: [{ type: "element-active", enable: false }],
      height: 254,
    } as PieConfig);

  /**
   * LineChart Config
   */
  const getLineChartConfig = (xField: string, yField: string) =>
    ({
      data: [],
      height: 150,
      xField,
      yField,
      autoFit: false,
      smooth: true,
      animation: {
        appear: {
          animation: "path-in",
          duration: 500,
        },
      },
    } as LineConfig);

  /**
   * 상품별 판매 순위 5위
   */
  const getProductRank = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let product_list = targetList.map((saleData) => ({
      id: saleData.product_id,
      product_name: saleData.product_name,
      cart_qty: 0,
    }));

    product_list = product_list.filter(
      (prod, index) =>
        index === product_list.findIndex((elem: any) => elem.id === prod.id)
    );

    targetList.forEach((salesItem) => {
      const item = product_list.find(
        (prod) => prod.id === salesItem.product_id
      );
      if (item) item.cart_qty += salesItem.cart_qty || 0;
    });

    const sorted = product_list
      .sort((a, b) => b.cart_qty - a.cart_qty)
      .slice(0, 5);

    return sorted;
  };

  /**
   * 카테고리별 판매 순위 5위
   */
  const [categoryViewType, setCategoryViewType] = useState<"list" | "chart">(
    "list"
  );
  const getCategoryRank = (limit?: number) => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let category_list = targetList.map((saleData) => ({
      id: saleData.category_id,
      category_name: categoryList && categoryList[saleData.category_id],
      cart_qty: 0,
    }));

    category_list = category_list.filter(
      (cate, index) =>
        index === category_list.findIndex((elem: any) => elem.id === cate.id)
    );

    targetList.forEach((salesItem) => {
      const item = category_list.find(
        (cate) => cate.id === salesItem.category_id
      );
      if (item) item.cart_qty += salesItem.cart_qty || 0;
    });

    const sorted = category_list.sort((a, b) => b.cart_qty - a.cart_qty);

    if (limit && limit > 0) return sorted.slice(0, limit);

    return sorted;
  };

  /**
   * 연령별 판매 비율
   */
  const getAgeRatio = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let age_list = targetList.map((saleData) => ({
      age: getAgeGroup(saleData.user_data.user_birth),
      cart_qty: 0,
    }));

    age_list = age_list.filter(
      (item, index) =>
        index === age_list.findIndex((elem: any) => elem.age === item.age)
    );

    targetList.forEach((salesItem) => {
      const item = age_list.find(
        (ageItem) => ageItem.age === getAgeGroup(salesItem.user_data.user_birth)
      );
      if (item) item.cart_qty += salesItem.cart_qty || 0;
    });

    const sorted = age_list
      .sort((a, b) => b.cart_qty - a.cart_qty)
      .map((item) => ({ ...item, age: `${item.age} 대` }));

    return sorted;
  };

  /**
   * 결제방식 비율
   */
  const getPaymentRatio = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let payment_list = targetList.map((saleData) => ({
      payment_type: saleData.payment_type,
      cart_qty: 0,
    }));

    payment_list = payment_list.filter(
      (item, index) =>
        index ===
          payment_list.findIndex(
            (elem: any) => elem.payment_type === item.payment_type
          ) && item.payment_type !== "recharge"
    );

    targetList.forEach((salesItem) => {
      const item = payment_list.find(
        (paymentItem) => paymentItem.payment_type === salesItem.payment_type
      );
      if (item) item.cart_qty += salesItem.cart_qty || 0;
    });

    const sorted = payment_list
      .sort((a, b) => b.cart_qty - a.cart_qty)
      .map((item) => ({
        ...item,
        payment_type: PAYMENT_TYPE[item.payment_type],
      }));

    return sorted;
  };

  /**
   * 판매수량 추이 DualAxes Config
   */
  const getDualAxesConfig = (
    xField: string | string[],
    yField: string | string[]
  ) =>
    ({
      data: [],
      height: 200,
      xField,
      yField,
      autoFit: false,
      geometryOptions: [
        {
          geometry: "column",
          content: (_ref: any) =>
            "".concat(commaNumber(_ref.value || 0), " 원"),
        },
        { geometry: "line", smooth: true },
      ],
      meta: {
        total: { alias: "판매금액" },
        count: { alias: "판매수량" },
      },
      tooltip: {
        formatter: (datum) => {
          const key = Object.keys(datum)[1];
          return {
            name: key === "total" ? "판매금액" : "판매수량",
            value:
              key === "total"
                ? `${commaNumber(datum[key]) || 0} 원`
                : `${datum[key]} 건`,
          };
        },
      },
    } as DualAxesConfig);

  /**
   * 판매수량 추이
   */
  const getSalesCount = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let date_list = targetList.map((saleData) => ({
      date: dayjs(saleData.created_at).format("YYYY-MM-DD"),
      total: 0,
      count: 0,
    }));

    date_list = date_list.filter(
      (item, index) =>
        index === date_list.findIndex((elem: any) => elem.date === item.date)
    );

    targetList
      .filter((item) => item.payment_type !== "recharge")
      .forEach((salesItem) => {
        const item = date_list.find(
          (dateItem) =>
            dateItem.date === dayjs(salesItem.created_at).format("YYYY-MM-DD")
        );
        if (item) {
          item.count += salesItem.cart_qty || 0;
          item.total += Number(salesItem.product_price_qty) || 0;
        }
      });

    const sorted = date_list.sort((a, b) =>
      dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1
    );

    return sorted;
  };

  /**
   * 총 매출금액 / 평균 금액
   */
  const [total, setTotal] = useState(0);
  const [average, setAverage] = useState(0);
  const getSalesTotal = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let date_list = targetList.map((saleData) => ({
      date: dayjs(saleData.created_at).format("YYYY-MM-DD"),
      count: 0,
    }));

    date_list = date_list.filter(
      (item, index) =>
        index === date_list.findIndex((elem: any) => elem.date === item.date)
    );

    let total = 0;

    targetList
      .filter((item) => item.payment_type !== "recharge")
      .forEach((item) => {
        total += item.product_price_qty;
      });

    setTotal(total);
    setAverage(Math.floor(total / date_list.length));
  };

  useEffect(() => {
    getSalesTotal();
  }, [salesList, rangeSalesList]);

  /**
   * 요일별 판매비율
   */
  const getDayRatio = () => {
    if (!salesList) return [];

    const targetList = rangeSalesList || salesList;

    let day_list = targetList.map((saleData) => ({
      day: saleData.created_day,
      cart_qty: 0,
    }));

    day_list = day_list.filter(
      (item, index) =>
        index === day_list.findIndex((elem: any) => elem.day === item.day)
    );

    targetList.forEach((salesItem) => {
      const item = day_list.find(
        (dateItem) => dateItem.day === salesItem.created_day
      );
      if (item) item.cart_qty += salesItem.cart_qty || 0;
    });

    const sorted = day_list.sort((a, b) => b.cart_qty - a.cart_qty);

    return sorted;
  };

  return (
    <CustomLayout>
      <CustomContent>
        <Row style={{ marginBottom: 24 }}>
          <Space>
            <Radio.Group
              value={targetType}
              onChange={handleTargetChange}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value="all">전체</Radio.Button>
              <Radio.Button value="range">기간별</Radio.Button>
            </Radio.Group>

            {targetType === "range" && (
              <DatePicker.RangePicker
                defaultValue={range}
                locale={locale}
                onChange={(res: RangeValue<dayjs.Dayjs>) => {
                  setRange(res);
                }}
              />
            )}
          </Space>
        </Row>

        <Row gutter={24}>
          {/* 상품별 판매순위 */}
          <Col {...topColResponsiveProps}>
            <Card
              title={"상품별 판매순위"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
            >
              <List
                itemLayout="horizontal"
                dataSource={getProductRank()}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<span>{index + 1}</span>}
                      title={
                        <a href="https://ant.design">{item.product_name}</a>
                      }
                    />
                    <div>{`${item.cart_qty} 건`}</div>
                  </List.Item>
                )}
              />
            </Card>
          </Col>

          {/* 카테고리별 판매순위 */}
          <Col {...topColResponsiveProps}>
            <Card
              title={"카테고리별 판매순위"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
              extra={
                <Button
                  shape="circle"
                  size={"small"}
                  icon={
                    categoryViewType === "list" ? (
                      <PieChartOutlined />
                    ) : (
                      <OrderedListOutlined />
                    )
                  }
                  style={{ borderWidth: 0 }}
                  onClick={() =>
                    categoryViewType === "list"
                      ? setCategoryViewType("chart")
                      : setCategoryViewType("list")
                  }
                />
              }
            >
              {categoryViewType === "list" ? (
                <List
                  itemLayout="horizontal"
                  dataSource={getCategoryRank(5)}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<span>{index + 1}</span>}
                        title={
                          <a href="https://ant.design">{item.category_name}</a>
                        }
                      />
                      <div>{`${item.cart_qty} 건`}</div>
                    </List.Item>
                  )}
                />
              ) : (
                <Pie
                  {...getPieChartConfig("cart_qty", "category_name")}
                  data={getCategoryRank()}
                />
              )}
            </Card>
          </Col>

          {/* 연령별 판매비율 */}
          <Col {...topColResponsiveProps}>
            <Card
              title={"연령별 판매비율"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
            >
              <Pie
                {...getPieChartConfig("cart_qty", "age")}
                data={getAgeRatio()}
              />
            </Card>
          </Col>

          {/* 결제방식 비율 */}
          <Col {...topColResponsiveProps}>
            <Card
              title={"결제방식 비율"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
            >
              <Pie
                {...getPieChartConfig("cart_qty", "payment_type")}
                data={getPaymentRatio()}
              />
            </Card>
          </Col>

          {/* 판매수량 추이 */}
          {/*<Col {...topColResponsiveProps} sm={24} md={24} lg={24} xl={12}>*/}
          {/*  <Card*/}
          {/*    title={"판매수량 추이"}*/}
          {/*    // loading={loading}*/}
          {/*    bodyStyle={{ padding: "20px 24px 8px 24px" }}*/}
          {/*  >*/}
          {/*    <Line*/}
          {/*      {...getLineChartConfig("date", "count")}*/}
          {/*      data={getSalesCount()}*/}
          {/*      meta={{*/}
          {/*        count: { alias: "수량" },*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  </Card>*/}
          {/*</Col>*/}
          <Col {...topColResponsiveProps} sm={24} md={24} lg={24} xl={12}>
            <Card
              title={"일별 판매현황"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
            >
              <DualAxes
                {...getDualAxesConfig("date", ["total", "count"])}
                data={[getSalesCount(), getSalesCount()]}
              />
            </Card>
          </Col>

          {/* 총 매출액 */}
          <Col {...topColResponsiveProps} style={{ textAlign: "left" }}>
            <Card
              title={"총 매출액"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px", height: 114 }}
              actions={[
                <Statistic
                  title="일평균 매출액"
                  value={average}
                  suffix={"원"}
                  style={{ lineHeight: 1.2, fontSize: 14 }}
                  valueStyle={{ fontSize: 16 }}
                />,
              ]}
            >
              <Statistic
                value={total}
                prefix={<ShoppingCartOutlined />}
                suffix={"원"}
              />
            </Card>
          </Col>

          {/* 요일별 판매비율 */}
          <Col {...topColResponsiveProps}>
            <Card
              title={"요일별 판매비율"}
              // loading={loading}
              bodyStyle={{ padding: "20px 24px 8px 24px" }}
            >
              <Pie
                {...getPieChartConfig("cart_qty", "day")}
                data={getDayRatio()}
              />
            </Card>
          </Col>
        </Row>
      </CustomContent>
    </CustomLayout>
  );
};

export default Closing;
