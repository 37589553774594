import React, { useCallback, useEffect, useState } from "react";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import { Button, Image, Input, InputNumber, message } from "antd";
import { createRandom, deepcopy, exportDataToXlsx } from "../../services/Util";
import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import CustomTable, { getColumnSearchProps } from "../components/CustomTable";
import { ProductListItem } from "./data";
import {
  getCategories,
  getProducts,
  insertProduct,
  updateProduct,
} from "../../services/FirebaseService";
import { ColumnType } from "antd/es/table";
import { FilterConfirmProps } from "antd/es/table/interface";
import logo from "../../assets/memart-logo.svg";
import ProductDetailForm from "./components/ProductDetailForm";
import { RolesItem } from "../Permission/components/data";
import dayjs from "dayjs";

interface CustomColumnType extends ColumnType<ProductListItem> {
  noFilter?: boolean;
}
const ProductPage: React.FC = () => {
  /**
   * @ko-KR ME마트 상품 목록
   * */
  const [productList, setProductList] = useState<ProductListItem[]>();
  // Searched Data
  const [filteredProductList, setFilteredProductList] =
    useState<ProductListItem[]>();
  /**
   * @ko-KR ME마트 상품 카테고리 목록
   * */
  const [categoryList, setCategoryList] = useState<{ [key: string]: string }>();

  /**
   * @ko-KR Modal에 전달할 상품 데이터
   * */
  const [currentRow, setCurrentRow] = useState<ProductListItem>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 카테고리 목록을 가져오는 함수
   */
  const getCategoryList = async () => {
    // API
    const categorySnapshot = await getCategories();

    let categories = {};
    categorySnapshot.docs.forEach((doc) => {
      categories = { ...categories, [doc.id]: doc.data().display_name };
    });

    setCategoryList(categories);
  };

  // getCategories callback 함수
  const categoryListCall = useCallback(async () => await getCategoryList(), []);

  useEffect(() => {
    categoryListCall();
  }, [categoryListCall]);

  /**
   * @ko-KR 상품 목록을 가져오는 함수
   */
  const getProductList = async () => {
    // API
    const products = await getProducts();

    const productList = products.map((prod: ProductListItem) => ({
      ...prod,
      display_hidden: prod.hidden === true ? "O" : "-",
    }));

    setProductList(productList);
    setTableLoader(false);
  };

  // getCategories callback 함수
  const productListCall = useCallback(async () => await getProductList(), []);

  useEffect(() => {
    productListCall();
  }, [productListCall]);

  useEffect(() => {
    if (categoryList && Object.keys(categoryList).length > 0) {
      getProductList();
    }
  }, [categoryList]);

  /**
   * @ko-KR 검색 관련 설정
   */
  const [searchInput, setSearchInput] = useState<Input | null>(null);
  const [searchText, setSearchText] = useState<string | number | boolean>("");
  const [searchedColumn, setSearchedColumn] = useState<
    string | number | boolean
  >("");

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: CustomColumnType[] = [
    {
      title: "이미지",
      dataIndex: "image_uri",
      noFilter: true,
      width: 100,
      render: (text) => {
        return (
          <Image
            width={100}
            src={(text as string) || logo}
            preview={!!text}
            fallback={logo}
            style={{ padding: 5 }}
          />
        );
      },
    },
    {
      title: "상품명",
      dataIndex: "product_name",
      width: 200,
    },
    {
      title: "카테고리",
      dataIndex: "category_id",
      width: 150,
      noFilter: true,
      render: (text) => categoryList && categoryList[text as string],
      filters:
        categoryList &&
        Object.keys(categoryList).map((key) => ({
          text: categoryList[key],
          value: key,
        })),
      onFilter: (value, record) => record.category_id === value,
    },
    {
      title: "상품가격",
      dataIndex: "product_price",
      width: 150,
      noFilter: true,
      render: (text) => (
        <InputNumber
          defaultValue={text}
          formatter={(value) =>
            `${value} 원`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          bordered={false}
          readOnly
        />
      ),
    },
    {
      title: "재고수량",
      dataIndex: "product_stock",
      width: 150,
      noFilter: true,
      render: (text) => `${text} 개`,
    },
    {
      title: "숨김여부",
      dataIndex: "display_hidden",
      width: 150,
      noFilter: true,
      filters: [
        {
          text: "숨김",
          value: "O",
        },
        {
          text: "보임",
          value: "-",
        },
      ],
      onFilter: (value, record) => record.display_hidden === value,
    },
    {
      title: "",
      width: 100,
      render: (text, row) => (
        <a
          onClick={() => {
            setCurrentRow(row);
            setVisible(true);
          }}
        >
          상세보기
        </a>
      ),
    },
  ];

  /**
   * @ko-KR Table 컬럼별 검색기능 추가
   * */
  const getFilteredColumn = () => {
    return columns.map((c: CustomColumnType) => {
      const columnSearch =
        c.title && c.dataIndex && !c.noFilter
          ? getColumnSearchProps(
              c.dataIndex as string,
              c.title as string,
              searchInput,
              searchText,
              searchedColumn,
              setSearchInput,
              setSearchText,
              setSearchedColumn,
              handleSearch,
              handleReset
            )
          : {};
      return { ...c, ...columnSearch };
    });
  };

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getProductList();
  };

  /**
   * @ko-KR Excel 다운로드 시, 사용하는 Data
   * */
  const getExcelHeader = () => {
    const mainHeader = deepcopy(columns)
      .map((col: ColumnType<ProductListItem>) => {
        return { title: col.title, dataIndex: col.dataIndex || "" };
      })
      .filter(
        (col: ColumnType<ProductListItem>) =>
          col.title !== "" &&
          col.dataIndex !== "" &&
          col.dataIndex !== "image_uri"
      );
    return [...mainHeader, { title: "바코드", dataIndex: "product_barcode" }];
  };

  const getExcelData = () => {
    if (!productList || productList.length === 0) return false;

    const targetData = filteredProductList || productList;

    return targetData.map((product) => {
      const { key, id, product_type, category_id, ...rest } = product;
      return {
        ...rest,
        category_id: (categoryList && categoryList[category_id]) || category_id,
      };
    });
  };

  /**
   * @ko-KR 상품 상세정보 모달
   */
  const [visible, setVisible] = useState(false);
  const handleDrawer = (visible: boolean, values?: ProductListItem) => {
    if (values) {
      const { product_stock, ...rest } = values;
      console.log(rest);

      // 선택된 데이터가 있다면 수정
      if (!!currentRow) {
        updateProduct(currentRow?.id!, rest).then(() => {
          setVisible(visible);
          refreshTable();
          message.success("수정완료", 1.5);
        });
      }
      // 없다면 등록
      else {
        insertProduct(rest).then(() => {
          setVisible(visible);
          refreshTable();
          message.success("등록완료", 1.5);
        });
      }
    } else setVisible(visible);
  };

  return (
    <CustomLayout>
      <CustomContent>
        <CustomTable
          columns={getFilteredColumn()}
          dataSource={productList}
          loading={tableLoader}
          pagination={{ pageSize: 10 }}
          rowKey="key"
          toolbar={{
            onRefresh: () => refreshTable(),
          }}
          toolbarRender={() => [
            <Button
              type={"primary"}
              key={"excel-button"}
              style={{
                borderWidth: 0,
                backgroundColor: "#217044",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => {
                const excelData = getExcelData();
                if (excelData)
                  exportDataToXlsx(
                    getExcelHeader(),
                    excelData,
                    "ME마트 상품 목록",
                    "상품 목록",
                    [25, 10, 10, 10, 10, 15],
                    [{ columnIdx: 5, format: "0" }]
                  );
              }}
              disabled={!productList}
            >
              <FileExcelOutlined /> 엑셀로 내보내기
            </Button>,
            <Button
              type="primary"
              key="add-button"
              style={{
                borderWidth: 0,
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => setVisible(true)}
            >
              <PlusOutlined /> 상품 추가하기
            </Button>,
          ]}
          onChange={(
            pagination: any,
            filters: any,
            sorter: any,
            extra: { currentDataSource: ProductListItem[] }
          ) => {
            if (extra.currentDataSource) {
              setFilteredProductList(extra.currentDataSource);
            }
          }}
        />
      </CustomContent>

      <ProductDetailForm
        visible={visible}
        onClose={() => {
          setCurrentRow(undefined);
          handleDrawer(false);
        }}
        currentProduct={currentRow}
        handleDrawer={handleDrawer}
        categories={categoryList || {}}
      />
    </CustomLayout>
  );
};

export default ProductPage;
