import firebase from "firebase/app";
import { ExtendedFirebaseInstance } from "react-redux-firebase";
import { deepcopy } from "./Util";
import dayjs from "dayjs";
import { RolesItem } from "../pages/Permission/components/data";

/**
 * 관리자의 권한을 가져오는 함수
 */
export const getUserRole = async (uid: string) => {
  const userSnapshot = await firebase
    .firestore()
    .collection("adminUsers")
    .doc(uid)
    .get();
  const userData = userSnapshot.data();
  return (userData && userData.role) || "";
};

/**
 * 관리자의 권한을 세팅해주는 함수
 */
export const setUserRole = async (
  uid: string,
  role: string = "new",
  callback: () => void
) => {
  const userSnapshot = await firebase
    .firestore()
    .collection("adminUsers")
    .doc(uid);
  if (userSnapshot.id === uid) {
    userSnapshot.update({ role: role }).then(() => {
      callback();
    });
  }
};

/**
 * 로그인 이후 처리함수
 * @param callback
 */
const handleSignIn = (callback: () => void) => {
  firebase
    .auth()
    // @ts-ignore
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      getUserRole(firebase.auth().currentUser!.uid).then((role) => {
        if (!role) {
          setUserRole(firebase.auth().currentUser!.uid, "new", callback);
        } else callback();
      });
    });
};

/**
 * 구글 로그인 함수
 */
export const signInWithGoogle = (
  firebase: ExtendedFirebaseInstance,
  callback: () => void
) => {
  try {
    // 일반적인 로그인
    firebase
      .login({
        provider: "google",
        type: "popup",
      })
      .then((res) => {
        handleSignIn(callback);
      });
  } catch (err) {
    console.error(err);
  }
};

/**
 * Users
 */
export const getUsers = async () => {
  const userSnapshot = await firebase
    .firestore()
    .collection("Users")
    .orderBy("user_name", "asc")
    .get();

  return userSnapshot.docs.map((doc, index) => {
    return { ...deepcopy(doc.data()), id: doc.id, key: index };
  });
};

export const getUser = async (doc: string) => {
  return await firebase.firestore().collection("Users").doc(doc).get();
};

export const updateUser = async (doc: string, userdata: any) => {
  return await firebase
    .firestore()
    .collection("Users")
    .doc(doc)
    .update(userdata);
};

/**
 * Products
 */
export const getProducts = async (
  fieldPath?: string,
  directionStr?: firebase.firestore.OrderByDirection
) => {
  const productSnapshot = await firebase
    .firestore()
    .collection("Products")
    .orderBy(fieldPath || "product_name", directionStr || "asc")
    .get();

  return productSnapshot.docs.map((doc, index) => {
    return { ...deepcopy(doc.data()), id: doc.id, key: index };
  });
};

export const insertProduct = async (productData: any) => {
  return await firebase
    .firestore()
    .collection("Products")
    .add({ ...productData, product_stock: 0 });
};

export const updateProduct = async (doc: string, productData: any) => {
  return await firebase
    .firestore()
    .collection("Products")
    .doc(doc)
    .update(productData);
};

export const uploadProductImage = async ({
  onError,
  onSuccess,
  onProgress,
  file,
  filename,
  setImageUrl,
}: any) => {
  const storage = firebase.storage();
  const metadata = {
    contentType: "image/png",
  };
  const storageRef = await storage.ref();
  const imgFile = storageRef.child(`products/${filename}.png`);
  onProgress(60);
  try {
    const image = await imgFile.put(file, metadata);
    const url = await image.ref.getDownloadURL();

    onSuccess("done", image);
    setImageUrl(url);
  } catch (e) {
    onError(e);
  }
};

/**
 * Categories
 */
export const getCategories = async () => {
  return await firebase.firestore().collection("Categories").get();
};

/**
 * Orders
 */
export const getOrders = async () => {
  const orderSnapshot = await firebase
    .firestore()
    .collection("Orders")
    .orderBy("created_at", "desc")
    .get();

  return orderSnapshot.docs.map((doc, index) => {
    return {
      ...deepcopy(doc.data()),
      id: doc.id,
      key: index,
      created_at: dayjs(doc.data().created_at.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
  });
};

export const getMonthlyOrders = async (month: string) => {
  const dateCheck = /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/.test(
    month
  );
  if (!dateCheck) {
    alert(
      "날짜 형식이 맞지 않습니다.\n관리자에게 문의해주세요.\n\n[에러코드] FBMONORD0122"
    );
  }

  return await firebase
    .firestore()
    .collection("Orders")
    .where("created_at", ">=", dayjs(month).toDate())
    .get();
};

/**
 * Keeping
 */
export const getKeepingItems = async () => {
  const keepingSnapshot = await firebase
    .firestore()
    .collection("Stores")
    .orderBy("created_at", "desc")
    .get();

  return keepingSnapshot.docs.map((doc, index) => {
    return {
      ...deepcopy(doc.data()),
      id: doc.id,
      key: index,
      created_at: dayjs(doc.data().created_at.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
  });
};

/**
 * Closing
 */
export const getClosingItems = async () => {
  const keepingSnapshot = await firebase
    .firestore()
    .collection("Closes")
    .orderBy("created_at", "desc")
    .get();

  return keepingSnapshot.docs.map((doc, index) => {
    return {
      ...deepcopy(doc.data()),
      id: doc.id,
      key: index,
      created_at: dayjs(doc.data().created_at.toDate()).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
  });
};

/**
 * Roles
 */
export const getRoles = async () => {
  const roleSnapshot = await firebase
    .firestore()
    .collection("Roles")
    .orderBy("created_at", "asc")
    .get();

  return roleSnapshot.docs.map((doc, index) => ({
    ...deepcopy(doc.data()),
    id: doc.id,
    key: index + 1,
    created_at: dayjs(doc.data().created_at.toDate()).format(
      "YYYY-MM-DD HH:mm:ss"
    ),
  }));
};

export const addRoles = async (roleData: RolesItem) => {
  const { id, key, ...rest } = roleData;
  await firebase
    .firestore()
    .collection("Roles")
    .add({
      ...rest,
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const updateRoles = async (doc: string, roleData: RolesItem) => {
  const { id, key, ...rest } = roleData;
  await firebase
    .firestore()
    .collection("Roles")
    .doc(doc)
    .update({
      ...rest,
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

export const deleteRoles = async (doc: string) => {
  await firebase.firestore().collection("Roles").doc(doc).delete();
};

/**
 * Settings
 */
export const getSetting = async (doc: any) => {
  const settingSnapshot = await firebase
    .firestore()
    .collection("Settings")
    .doc(doc)
    .get();
  const setting = settingSnapshot.data();

  return setting ? setting.data : null;
};

export const updateSetting = async (doc: any, data: any) => {
  const fnCollection = firebase.firestore().collection("Settings").doc(doc);

  const exist = await fnCollection.get();

  if (exist.data()) await fnCollection.update(data);
  else await fnCollection.set(data);
};

/**
 * adminUsers
 */
export const getAdmins = async () => {
  const adminSnapshot = await firebase
    .firestore()
    .collection("adminUsers")
    .get();

  return adminSnapshot.docs.map((admin, index) => {
    return {
      ...deepcopy(admin.data()),
      id: admin.id,
      key: index,
    };
  });
};

export const updateAdmins = async (doc: string, data: any) => {
  return await firebase
    .firestore()
    .collection("adminUsers")
    .doc(doc)
    .update(data);
};
