import React from "react";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import { Tabs } from "antd";
import TabRoles from "./components/TabRoles";
import TabMenus from "./components/TabMenus";
import TabUsers from "./components/TabUsers";

const Permission: React.FC = () => {
  return (
    <CustomLayout>
      <Tabs
        type={"card"}
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          minHeight: 280,
        }}
      >
        <Tabs.TabPane
          tab={"권한목록"}
          key={"permission"}
          children={<TabRoles />}
        />
        <Tabs.TabPane
          tab={"메뉴별 설정"}
          key={"menu"}
          children={<TabMenus />}
        />
        <Tabs.TabPane
          tab={"사용자 설정"}
          key={"users"}
          children={<TabUsers />}
        />
      </Tabs>
    </CustomLayout>
  );
};

export default Permission;
