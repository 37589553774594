import React, { useState } from "react";
import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  Row,
  Spin,
} from "antd";
import { UserListItem } from "../data";
import dayjs from "dayjs";
import DatePicker from "../../components/DatePicker";

interface UserDetailFormProps extends DrawerProps {
  currentUser: UserListItem | undefined;
  handleDrawer: (visible: boolean, values?: UserListItem) => void;
}
const UserDetailForm = ({
  currentUser,
  handleDrawer,
  ...rest
}: UserDetailFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Drawer
      {...rest}
      title="사용자 상세정보"
      width={720}
      placement="right"
      closable={false}
      afterVisibleChange={() => {
        form.resetFields();
        setLoading(false);
      }}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            key="cancel_btn"
            style={{ marginRight: 10 }}
            onClick={() => {
              form.resetFields();
              handleDrawer(false);
            }}
          >
            취소
          </Button>
          <Button
            key="save_btn"
            type={"primary"}
            onClick={async () => {
              setLoading(true);
              const values = await form.validateFields();
              form.resetFields();
              handleDrawer(false, {
                ...values,
                user_birth: values.user_birth.format("YYYY-MM-DD"),
              });
            }}
          >
            저장
          </Button>
        </div>
      }
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout={"vertical"}
          requiredMark={true}
          initialValues={{
            ...currentUser,
            user_birth: dayjs(currentUser?.user_birth),
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"user_name"}
                label={"사용자명"}
                rules={[{ required: true, message: "사용자명을 입력해주세요" }]}
              >
                <Input
                  placeholder={"사용자명을 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"user_no"} label={"회원번호"}>
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={"회원번호를 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={"user_birth"} label={"생년월일"}>
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder={"생년월일을 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"user_phone"} label={"휴대폰번호"}>
                <Input
                  style={{ width: "100%" }}
                  placeholder={"휴대폰번호를 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default UserDetailForm;
