import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Skeleton, Statistic, Tooltip } from "antd";
import CustomLayout, { CustomContent } from "../components/CustomLayout";

import { Line, LineConfig } from "@ant-design/charts";
import dayjs, { Dayjs } from "dayjs";
import { getMonthlyOrders } from "../../services/FirebaseService";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  InfoCircleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isPositive } from "../../services/Util";
import firebase from "firebase/app";
import { PREFIX_PATH } from "../../Routes/components/MenuList";
import { useHistory } from "react-router-dom";

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 24 },
};

const Dashboard: React.FC = () => {
  const history = useHistory();

  /**
   * 날짜 기준 데이터
   */
  // const today = dayjs().format('YYYY-MM-DD');
  const month = dayjs().format("YYYY-MM-01");

  /**
   * 데이터 가져오는 중 체크
   */
  const [loading, setLoading] = useState(true);

  /**
   * 매출 State
   */
  const [monthlySales, setMonthlySales] = useState(0);
  const [dailySales, setDailySales] = useState(0);
  const [salesDrift, setSalesDrift] = useState(0);

  /**
   * 판매그래프 State
   */
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [orderCountArr, setOrderCountArr] =
    useState<{ x: string; y: number }[]>();

  /**
   * 회원 수 State
   */
  const [userCount, setUserCount] = useState(0);
  const [newUserCount, setNewUserCount] = useState(0);

  /**
   * 이번 달 매출 데이터 가져오기
   */
  const getMonthlyOrder = async () => {
    const orderSnapshot = await getMonthlyOrders(month);
    const orders = orderSnapshot.docs.map((doc) => {
      const order_count = doc
        .data()
        .order_items.reduce(
          (total: any, obj: { cart_qty: any }) => obj.cart_qty + total,
          0
        );

      return {
        order_count: Number(order_count),
        total_price: Number(doc.data().total_price),
        created_at: dayjs(doc.data().created_at.toDate()),
      };
    });

    if (orders.length > 0) {
      const userSnapshot = await firebase.firestore().collection("Users").get();
      setUserCount(userSnapshot.size);
      const newUserSnapshot = await firebase
        .firestore()
        .collection("Users")
        .where("user_no", "==", 0)
        .get();
      setNewUserCount(newUserSnapshot.size);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setSalesData(orders);
    }
  };

  /**
   * 매출 데이터 가공
   */
  const setSalesData = (
    orderData: { order_count: number; total_price: number; created_at: Dayjs }[]
  ) => {
    // loading 초기화
    setLoading(true);

    // 이번 달 매출
    let vMonthlySales = 0;
    // 금일 매출
    let vDailySales = 0;
    // 전일 매출
    let vYesterdaySales = 0;
    // 이번 달 판매수량
    let vMonthlyOrderCount = 0;
    // 판매 그래프 설정
    const arrOrderDate: string[] = [];
    const arrOrderCount: number[] = [];
    let tmpOrderCount = 0;

    orderData.forEach((data, index) => {
      vMonthlySales += data.total_price;
      vMonthlyOrderCount += data.order_count;
      // 전일 매출
      if (dayjs().format("DD") === data.created_at.format("DD"))
        vDailySales += data.total_price;
      // 2일전 매출
      else if (
        dayjs().subtract(1, "day").format("DD") === data.created_at.format("DD")
      )
        vYesterdaySales += data.total_price;

      /**
       * 판매 그래프 로직
       */
      const orderDate = data.created_at.format("YYYY-MM-DD");
      // 날짜가 배열에 있는 경우,
      if (arrOrderDate.indexOf(orderDate) > -1) {
        // 1. 임시 수량에 추가
        tmpOrderCount += data.order_count;

        // 2. 마지막 item의 경우, 바로 넣어주기
        if (orderData.length === index + 1) {
          arrOrderCount.push(tmpOrderCount);
        }
      }
      // 새로운 날짜의 경우,
      else {
        // 1. 이전 날짜 수량을 넣어주고
        arrOrderCount.push(tmpOrderCount);
        // 2. 새로운 날짜를 넣어주고
        arrOrderDate.push(orderDate);
        // 3. 임시 수량 다시 카운트
        tmpOrderCount = data.order_count;
      }
    });

    // 추이 계산
    const vSalesDrift = Math.floor(
      ((vDailySales - vYesterdaySales) / vYesterdaySales) * 100
    );

    // 판매그래프 새로운 날짜 1번에 대한 방지
    // 맨 처음 들어가는 값은 무조건 0이 된다.
    arrOrderCount.shift();

    const totalCountArr = [...Array(arrOrderCount.length)].map((x, index) => {
      return { x: arrOrderDate[index], y: arrOrderCount[index] };
    });

    // set State
    setMonthlySales(vMonthlySales);
    setDailySales(vYesterdaySales);
    setSalesDrift(vSalesDrift);

    setTotalOrderCount(vMonthlyOrderCount);
    setOrderCountArr(totalCountArr);

    // loading 초기화
    setLoading(false);
  };

  // getMonthlyOrder callback 함수
  const orderCall = useCallback(async () => await getMonthlyOrder(), []);

  useEffect(() => {
    orderCall();
  }, [orderCall]);

  const config: LineConfig = {
    data: [],
    height: 300,
    xField: "x",
    yField: "y",
    autoFit: false,
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 500,
      },
    },
    meta: {
      y: { alias: "수량" },
    },
  };
  return (
    <CustomLayout>
      <CustomContent>
        <Row gutter={24}>
          {/* 당월 매출액 */}
          <Col {...topColResponsiveProps}>
            <Skeleton active loading={loading}>
              <Card
                actions={[
                  <Tooltip title={"판매현황"}>
                    <div onClick={() => history.push(PREFIX_PATH + "/sales")}>
                      <Statistic
                        title="전일 매출액"
                        value={dailySales}
                        suffix={"원"}
                        style={{ lineHeight: 1.2, fontSize: 14 }}
                        valueStyle={{ fontSize: 16 }}
                      />
                    </div>
                  </Tooltip>,
                  <Tooltip title={"판매현황"}>
                    <div onClick={() => history.push(PREFIX_PATH + "/sales")}>
                      <Statistic
                        title="일일 매출 추이"
                        value={salesDrift}
                        precision={2}
                        style={{ lineHeight: 1.2, fontSize: 14 }}
                        valueStyle={{
                          fontSize: 16,
                          color: isPositive(Math.sign(salesDrift))
                            ? "#3f8600"
                            : "#cf1322",
                        }}
                        prefix={
                          isPositive(Math.sign(salesDrift)) ? (
                            <ArrowUpOutlined />
                          ) : (
                            <ArrowDownOutlined />
                          )
                        }
                        suffix="%"
                      />
                    </div>
                  </Tooltip>,
                ]}
              >
                <Statistic
                  title="당월 매출액"
                  value={monthlySales}
                  prefix={<ShoppingCartOutlined />}
                  suffix={"원"}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Skeleton>
          </Col>

          {/* 회원 수 */}
          <Col {...topColResponsiveProps}>
            <Skeleton active loading={loading}>
              <Card
                actions={[
                  <Tooltip title={"사용자 관리"}>
                    <div onClick={() => history.push(PREFIX_PATH + "/users")}>
                      <Statistic
                        title="승인대기"
                        value={newUserCount}
                        suffix={"명"}
                        style={{ lineHeight: 1.2, fontSize: 14 }}
                        valueStyle={{ fontSize: 16 }}
                      />
                    </div>
                  </Tooltip>,
                ]}
              >
                <Statistic
                  title="회원 수"
                  value={userCount}
                  prefix={<UserOutlined />}
                  suffix={"명"}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Skeleton>
          </Col>
        </Row>

        <Card
          bordered={false}
          title={"당월 판매수량"}
          extra={
            <Tooltip title={"결제내역 이동"}>
              <InfoCircleOutlined
                onClick={() => history.push(PREFIX_PATH + "/sales")}
              />
            </Tooltip>
          }
          loading={loading}
          bodyStyle={{ padding: "20px 24px 8px 24px" }}
        >
          {orderCountArr && <Line {...config} data={orderCountArr} />}
        </Card>
      </CustomContent>
    </CustomLayout>
  );
};

export default Dashboard;
