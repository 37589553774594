import React from "react";
import "./Profile.css";
import CustomLayout from "../components/CustomLayout";
import { Avatar, Card, Form, Layout, List, PageHeader, Tabs } from "antd";
import { useSelector } from "react-redux";
import firebase from "firebase";

const { Content } = Layout;

const Profile = () => {
  const [form] = Form.useForm();

  const { photoURL, displayName, email, createdAt } = useSelector(
    (state: any) => {
      return state.firebase.auth;
    }
  );
  const { role } = useSelector((state: any) => state.authReducer);

  return (
    <CustomLayout>
      <Content
        className="site-layout-background"
        style={{
          margin: "24px 16px",
          padding: 24,
          paddingLeft: 0,
          minHeight: 280,
        }}
      >
        {/*<h1>Profile</h1>*/}
        {/*<Typography.Paragraph>프로필 이미지</Typography.Paragraph>*/}
        {/*<Image src={photoURL} />*/}
        {/*<Typography.Paragraph>사용자명: {displayName}</Typography.Paragraph>*/}
        {/*<Typography.Paragraph>이메일: {email}</Typography.Paragraph>*/}
        {/*<Typography.Paragraph>권한: {role}</Typography.Paragraph>*/}
        {/*<Typography.Paragraph>*/}
        {/*  생성일자: {dayjs.unix(createdAt / 1000).format("YYYY-MM-DD")}*/}
        {/*</Typography.Paragraph>*/}
        <Tabs tabPosition={"left"}>
          <Tabs.TabPane tab="프로필" key="profile">
            <PageHeader title={"프로필 관리"} className="site-page-header" />
            <Card
              title={"현재 구글로 로그인 중입니다"}
              style={{ textAlign: "left" }}
              bodyStyle={{ paddingTop: 0, paddingBottom: 0, textAlign: "left" }}
            >
              <List.Item
                actions={[
                  <a
                    key="sign-out-btn"
                    onClick={() => {
                      firebase.auth().signOut();
                    }}
                  >
                    로그아웃
                  </a>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={photoURL} />}
                  title={<a>{displayName}</a>}
                  description={email}
                />
              </List.Item>
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </Content>
    </CustomLayout>
  );
};

export default Profile;
