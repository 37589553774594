import React, { useCallback, useEffect, useState } from "react";
import {
  getAdmins,
  getRoles,
  updateAdmins,
} from "../../../services/FirebaseService";
import { Button, Dropdown, Input, Menu, Typography } from "antd";
import { AdminUserItem, RolesItem } from "./data";
import { ColumnType } from "antd/es/table";
import CustomTable, {
  getColumnSearchProps,
} from "../../components/CustomTable";
import { FilterConfirmProps } from "antd/es/table/interface";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";

interface CustomColumnType extends ColumnType<AdminUserItem> {
  noFilter?: boolean;
}
const TabUsers = () => {
  /**
   * @ko-KR 사용자 목록
   * */
  const [adminUsers, setAdminUsers] = useState<AdminUserItem[]>();

  /**
   * @ko-KR 권한 목록
   * */
  const [roles, setRoles] = useState<RolesItem[]>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 사용자 목록을 가져오는 함수
   */
  const getUserList = async () => {
    const adminUsers = await getAdmins();

    setAdminUsers(adminUsers);
    setTableLoader(false);
  };

  const getUserListCall = useCallback(async () => await getUserList(), []);

  /**
   * @ko-KR 권한 목록을 가져오는 함수
   */
  const getRoleList = async () => {
    // API
    const roles = await getRoles();

    setRoles(roles);
    getUserListCall();
  };

  const getRoleListCall = useCallback(async () => await getRoleList(), []);
  useEffect(() => {
    getRoleListCall();
  }, [getRoleListCall]);

  /**
   * @ko-KR 검색 관련 설정
   */
  const [searchInput, setSearchInput] = useState<Input | null>(null);
  const [searchText, setSearchText] = useState<string | number | boolean>("");
  const [searchedColumn, setSearchedColumn] = useState<
    string | number | boolean
  >("");

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: CustomColumnType[] = [
    {
      title: "사용자명",
      dataIndex: "displayName",
      width: 200,
    },
    {
      title: "권한코드",
      dataIndex: "role",
      width: 200,
      noFilter: true,
      render: (text, record) => {
        const role = roles?.find((r) => r.role === (record.newRole || text));
        const title = role
          ? role.title
          : (record.newRole || text) === "new"
          ? "신규"
          : "-";
        return (
          <Dropdown
            overlay={
              <Menu onClick={(item) => handleChange(record.id || "", item.key)}>
                <Menu.Item
                  key="new"
                  disabled={(record.newRole || text) === "new"}
                >
                  신규
                </Menu.Item>
                {roles &&
                  roles.map((role) => (
                    <Menu.Item
                      key={role.role}
                      disabled={(record.newRole || text) === role.role}
                    >
                      {role.title}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => e.preventDefault()}
            >
              {title} <EditOutlined />
            </span>
          </Dropdown>
        );
      },
    },
    {
      title: "Action",
      width: 200,
      render: (_, record) => (
        <>
          {/* (새로운 값을 선택 || 선택한 값이 기존의 값과 다를 경우) 에만 활성화 */}
          {[
            buttonSave(
              record,
              !!record.newRole && record.newRole !== record.role
            ),
            buttonCancel(
              record,
              !!record.newRole && record.newRole !== record.role
            ),
          ]}
        </>
      ),
    },
  ];

  const handleChange = (adminId: string, newRole: string) => {
    if (!adminUsers) return;

    const changedAdminList: AdminUserItem[] = adminUsers.map((admin) => {
      return admin.id === adminId ? { ...admin, newRole } : admin;
    });

    setAdminUsers(changedAdminList);
  };

  const buttonSave = (record: AdminUserItem, isNew?: boolean) => (
    <Button
      key={"buttonSave"}
      type={"link"}
      style={{ borderWidth: 0 }}
      disabled={!isNew}
      onClick={async () => {
        record.role = record.newRole || record.role;
        await updateAdmins(record.id as string, { role: record.role });
        refreshTable();
      }}
    >
      적용
    </Button>
  );

  const buttonCancel = (record: AdminUserItem, isNew?: boolean) => (
    <Button
      key={"buttonCancel"}
      type={"link"}
      style={{ borderWidth: 0, color: !isNew ? "#0000003F" : "red" }}
      disabled={!isNew}
      onClick={() => {
        const canceledList = adminUsers?.map((admin) => {
          const { newRole, ...rest } = record;
          return admin.id === record.id ? rest : admin;
        });

        setAdminUsers(canceledList);
      }}
    >
      취소
    </Button>
  );

  /**
   * @ko-KR Table 컬럼별 검색기능 추가
   * */
  const getFilteredColumn = () => {
    return columns.map((c: CustomColumnType) => {
      const columnSearch =
        c.title && c.dataIndex && !c.noFilter
          ? getColumnSearchProps(
              c.dataIndex as string,
              c.title as string,
              searchInput,
              searchText,
              searchedColumn,
              setSearchInput,
              setSearchText,
              setSearchedColumn,
              handleSearch,
              handleReset
            )
          : {};
      return { ...c, ...columnSearch };
    });
  };

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getUserList();
  };

  return (
    <CustomTable
      columns={getFilteredColumn()}
      dataSource={adminUsers}
      loading={tableLoader}
      pagination={{ pageSize: 20 }}
      rowKey="key"
      toolbar={{
        onRefresh: () => refreshTable(),
      }}
      leftToolbarRender={() => [
        <Typography.Paragraph
          key={"notice"}
          style={{ margin: 0, marginTop: 5, color: "#d9363e" }}
        >
          <InfoCircleOutlined /> 사용자 권한 변경 시, 새로고침 후에 적용됩니다.
        </Typography.Paragraph>,
      ]}
    />
  );
};

export default TabUsers;
