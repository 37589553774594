import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";

type PrivateRouteProps = {
  children: React.ReactNode;
  path: string;
};
const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const auth = useSelector((state: any) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
