import XLSX from "xlsx";
import { ReactNode } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs/esm";
import { RcFile } from "antd/es/upload";

export const DAY_ARR = ["월", "화", "수", "목", "금", "토", "일"];

export const deepcopy = (obj: object | []) => {
  return JSON.parse(JSON.stringify(obj));
};

export const commaNumber = (number: number | string | undefined | null) => {
  if (!number) return "";
  return `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNumber = (
  phoneNumber: string | ReactNode,
  masking?: boolean
) => {
  // Filter only numbers from the input
  const cleaned = `${phoneNumber}`.replace(/\D/g, "");

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})/);

  if (match) {
    const match1 = match[1];
    let match2 = match[2];
    const match3 = match[3];
    if (masking) {
      const midNum = match2.split("");
      match2 = "";
      for (let i = 0; i < midNum.length; i++) {
        match2 += i === 0 || i === midNum.length - 1 ? midNum[i] : "*";
      }
    }
    return `${match1}-${match2}-${match3}`;
  }

  return null;
};

const GetExcelColumnName = (columnNumber: number) => {
  const ordA = "a".charCodeAt(0);
  const ordZ = "z".charCodeAt(0);
  const len = ordZ - ordA + 1;

  let s = "";
  while (columnNumber >= 0) {
    s = String.fromCharCode((columnNumber % len) + ordA) + s;
    columnNumber = Math.floor(columnNumber / len) - 1; // eslint-disable-line no-param-reassign
  }
  return s.toUpperCase();
};

/**
 * Excel Download
 */
export const exportDataToXlsx = (
  header: { title: string; dataIndex: string }[],
  data: any[],
  filename: string,
  worksheetName?: string,
  columnWidthArray?: number[],
  formatColumns?: { columnIdx: number; format: string }[]
) => {
  // Excel 파일 생성
  const new_workbook = XLSX.utils.book_new();

  // 헤더의 제목만 가져오기
  const getHeaderTitle = header.map((h) => {
    return h.title;
  });

  // 헤더 내용 넣어주면서 WS 생성
  const worksheet = XLSX.utils.aoa_to_sheet([getHeaderTitle]);

  // 헤더 기준으로 데이터 입력
  header
    // key 값만 return
    .map((h) => {
      return h.dataIndex;
    })
    // key 값들로 loop
    .forEach((key, index) => {
      // 해당 Key 값으로 데이터 추출
      // 빈 값은 '-' 처리
      const filteredData = data.map((item) => {
        return { [key]: item[key] ? item[key] : "-" };
      });

      // 해당 key 열에 추가
      XLSX.utils.sheet_add_json(worksheet, filteredData, {
        origin: `${GetExcelColumnName(index)}2`,
        skipHeader: true,
      });
    });

  // set column width
  if (columnWidthArray && columnWidthArray.length > 0) {
    const cols = columnWidthArray.map((width: number) => ({
      wch: width,
    }));

    worksheet["!cols"] = cols;
  }

  // 자동 필터 처리
  worksheet["!autofilter"] = {
    ref: `A1:${GetExcelColumnName(header.length - 1)}1`,
  };

  // 데이터의 마지막 열 구하기
  const range = XLSX.utils.decode_range(worksheet["!ref"] as string);
  const num_rows = range.e.r - range.s.r + 1;
  // const num_cols = range.e.r - range.s.r + 1;

  if (formatColumns && formatColumns.length > 0) {
    formatColumns.forEach((colData) => {
      // 포맷 형식
      const fmt = colData.format;

      // E열의 포맷을 모두 숫자로 적용
      [...Array(num_rows)].forEach((d, i) => {
        worksheet[`${GetExcelColumnName(colData.columnIdx)}${i + 1}`].z = fmt;
        worksheet[`${GetExcelColumnName(colData.columnIdx)}${i + 1}`].s = {
          alignment: { textRotation: 90 },
        };
      });
    });
  }

  // WS 를 Excel 에 입력
  XLSX.utils.book_append_sheet(
    new_workbook,
    worksheet,
    worksheetName || "SheetJS"
  );

  // Excel Export
  XLSX.writeFile(new_workbook, `${filename}.xlsx`);
};

export const searchDataFromBase = (
  baseData: any[] | undefined,
  searchValue: string,
  setter?: React.Dispatch<React.SetStateAction<any>>
) => {
  if (!baseData) return false;

  const filteredData = baseData.filter((p) =>
    Object.keys(p).some((k) =>
      String(p[k]).toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  if (setter) setter(filteredData);
  return filteredData;
};

export const getBase64 = (file: RcFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isPositive = (num: number) => {
  return num >= 0;
};

/**
 * Cell merge processing
 * @param text the value of the current cell
 * @param data All data in the current page
 * @param key dataIndex of the current column
 * @param index The index of the current data
 * @returns {number} the number of cells to be merged
 */
export const mergeCells = (
  text: string,
  data: any[],
  key: string,
  index: number
) => {
  // Is the data in the previous row the same?
  if (index !== 0 && text === data[index - 1][key]) {
    return 0;
  }
  let rowSpan = 1;
  // Determine whether the next line is equal
  for (let i = index + 1; i < data.length; i++) {
    if (text !== data[i][key]) {
      break;
    }
    rowSpan += 1;
  }
  return rowSpan;
};

export const getAge = (birthDate: string | dayjs.Dayjs) => {
  // eslint-disable-next-line no-param-reassign
  birthDate = dayjs(birthDate);
  return dayjs().diff(birthDate.format("YYYY"), "years") + 1;
};

export const getAgeGroup = (birthDate: string | Dayjs) => {
  const age = getAge(birthDate);
  return Math.floor(age / 10) * 10;
};

export const getDayFilterData = () => {
  return DAY_ARR.map((day) => ({ text: day, value: day }));
};

export const createRandom = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
