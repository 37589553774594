import React, { useState } from "react";
import "./ProductDetailForm.css";
import {
  Button,
  Col,
  Drawer,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Upload,
} from "antd";
import { ProductListItem } from "../data";
import { UploadOutlined } from "@ant-design/icons";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { getBase64 } from "../../../services/Util";
import { uploadProductImage } from "../../../services/FirebaseService";

interface ProductDetailFormProps extends DrawerProps {
  currentProduct?: ProductListItem | undefined;
  handleDrawer: (visible: boolean, values?: ProductListItem) => void;
  categories: { [key: string]: string };
}
const ProductDetailForm = ({
  currentProduct,
  handleDrawer,
  categories,
  ...rest
}: ProductDetailFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [imageUrl, setImageUrl] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  /**
   * Drawer 가 열리고 닫힐 때 발생하는 함수
   * @param visible
   */
  const handleVisible = (visible: boolean) => {
    form.resetFields();
    setLoading(false);

    if (!visible) {
      setImageUrl("");
      setFileList(undefined);
    } else {
      if (currentProduct?.image_uri) {
        setImageUrl(currentProduct?.image_uri);
        setFileList([
          {
            uid: "-1",
            name: currentProduct?.product_name,
            status: "done",
            url: currentProduct?.image_uri,
            thumbUrl: currentProduct?.image_uri,
          },
        ]);
      }
    }
  };

  /**
   * 이미지 형식 파일인지 검사
   * @param file
   */
  const beforeUpload = (file: RcFile) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("이미지 파일만 업로드가 가능합니다.");
    } else setFileList(undefined);

    return isImage;
  };

  /**
   * 파일 업로드 시,
   * @param info
   */
  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj!).then((res) => {
        const url = res as string;

        setFileList([
          {
            uid: "-1",
            name: currentProduct?.product_name || "",
            status: "done",
            url: url,
            thumbUrl: url,
          },
        ]);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  /**
   * 미리보기 모달 설정
   * @param uploadFile
   */
  const handlePreview = async (uploadFile: UploadFile) => {
    setPreviewTitle(uploadFile.name || "");
    setPreviewVisible(true);
  };

  const handleRemove = () => {
    setFileList(undefined);
    setImageUrl("");
  };

  return (
    <Drawer
      {...rest}
      title="상품 상세정보"
      width={720}
      placement="right"
      closable={false}
      afterVisibleChange={handleVisible}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            key="cancel_btn"
            style={{ marginRight: 10 }}
            onClick={() => {
              form.resetFields();
              handleDrawer(false);
            }}
          >
            취소
          </Button>
          <Button
            key="save_btn"
            type={"primary"}
            onClick={async () => {
              setLoading(true);
              const values = await form.validateFields();
              form.resetFields();
              handleDrawer(false, {
                ...values,
                hidden: values.hidden || false,
                image_uri: imageUrl,
              });
            }}
          >
            저장
          </Button>
        </div>
      }
      destroyOnClose={true}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout={"vertical"}
          requiredMark={true}
          initialValues={currentProduct}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"product_name"}
                label={"상품명"}
                rules={[{ required: true, message: "상품명을 입력해주세요" }]}
              >
                <Input
                  placeholder={"상품명을 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"product_barcode"}
                label={"상품 바코드"}
                rules={[
                  { required: true, message: "상품 바코드를 입력해주세요" },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={"상품 바코드를 입력해주세요"}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"product_price"}
                label={"상품 가격"}
                rules={[
                  { required: true, message: "상품 가격을 입력해주세요" },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={"상품 가격을 입력해주세요"}
                  autoComplete="off"
                  step={100}
                  required
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"product_stock"} label={"상품 재고"}>
                <Input style={{ width: "100%" }} disabled addonAfter={"개"} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"category_id"}
                label={"카테고리"}
                rules={[{ required: true, message: "카테고리를 선택해주세요" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="카테고리를 선택해주세요"
                  filterOption={(input, option) =>
                    option?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.keys(categories).map((key) => (
                    <Select.Option key={key} value={key}>
                      {categories[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"hidden"}
                label={"상품 숨기기"}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Upload
              name={"file"}
              maxCount={1}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onPreview={handlePreview}
              onRemove={handleRemove}
              customRequest={(options) =>
                uploadProductImage({
                  ...options,
                  filename: currentProduct && currentProduct.id,
                  setImageUrl,
                })
              }
              listType="picture"
              className="upload-list-inline"
              fileList={fileList}
              disabled={!currentProduct}
            >
              <Button icon={<UploadOutlined />} disabled={!currentProduct}>
                상품 이미지 업로드
              </Button>
              {!currentProduct && (
                <div className="formik-caption" style={{ marginTop: 4 }}>
                  이미지는 상품 등록 후, 가능합니다.
                </div>
              )}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={() => {
                setPreviewVisible(false);
              }}
            >
              <img alt="임시 이미지" style={{ width: "100%" }} src={imageUrl} />
            </Modal>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default ProductDetailForm;
