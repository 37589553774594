import React, { useCallback, useEffect, useState } from "react";
import { Menu } from "antd";
import {
  FundProjectionScreenOutlined,
  InboxOutlined,
  LockOutlined,
  PieChartOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getSetting } from "../../services/FirebaseService";
import { menuItem } from "../../pages/Permission/components/TabMenus";
import { SET_MENU } from "../../ducks/authReducer";

import Dashboard from "../../pages/Dashboard/Dashboard";
import UserPage from "../../pages/Users/UserPage";
import ProductPage from "../../pages/Products/ProductPage";
import SalesPage from "../../pages/Sales/SalesPage";
import Keeping from "../../pages/Keeping/Keeping";
import Closing from "../../pages/Closing/Closing";
import Permission from "../../pages/Permission/Permission";
import Profile from "../../pages/Profile/Profile";

export const PREFIX_PATH = "/index";
export const pageList = [
  {
    key: "/dashboard",
    icon: <PieChartOutlined />,
    title: "대시보드",
    path: "/dashboard",
    pageId: "dashboard",
    component: <Dashboard />,
  },
  {
    key: "/profile",
    title: "프로필",
    path: "/profile",
    pageId: "profile",
    component: <Profile />,
    hideMenu: true,
  },
  {
    key: "/users",
    icon: <UserOutlined />,
    title: "사용자 관리",
    path: "/users",
    pageId: "users",
    component: <UserPage />,
  },
  {
    key: "/products",
    icon: <InboxOutlined />,
    title: "상품 관리",
    path: "/products",
    pageId: "products",
    component: <ProductPage />,
  },
  {
    key: "/sales",
    icon: <PieChartOutlined />,
    title: "판매 현황",
    path: "/sales",
    pageId: "sales",
    component: <SalesPage />,
  },
  {
    key: "/keeping",
    icon: <TagOutlined />,
    title: "입고 현황",
    path: "/keeping",
    pageId: "keeping",
    component: <Keeping />,
  },
  {
    key: "/closing",
    icon: <FundProjectionScreenOutlined />,
    title: "마감 현황",
    path: "/closing",
    pageId: "closing",
    component: <Closing />,
  },
  {
    key: "/authorities",
    icon: <LockOutlined />,
    title: "권한 설정",
    path: "/authorities",
    pageId: "authorities",
    component: <Permission />,
  },
];

const MenuList: React.FC = () => {
  const { role, gMenuData } = useSelector((state: any) => state.authReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  const isAllowed = (permissions: string[]) => {
    return permissions.indexOf(role) > -1;
  };

  /**
   * 설정에서 메뉴 구성 가져오기
   */
  const [menuSetting, setMenuSetting] = useState<menuItem[]>();
  const getMenuPermission = async () => {
    const menuData = await getSetting("menu-permission");
    // 사용자 권한 지정해주기
    dispatch({ type: SET_MENU, gMenuData: menuData });
    // state 저장
    setMenuSetting(menuData);
  };
  const getMenuCall = useCallback(async () => await getMenuPermission(), []);
  useEffect(() => {
    getMenuCall();
  }, [getMenuCall]);

  const getMenuRole = (pageId: string) => {
    return (
      (gMenuData && gMenuData.find((m: any) => m.key === pageId)?.data) ||
      (menuSetting && menuSetting.find((m) => m.key === pageId)?.data) ||
      []
    );
  };

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={[window.location.pathname.replace("/index", "")]}
      mode="inline"
    >
      {pageList.map(
        (page, index) =>
          !page.hideMenu && (
            <Menu.Item
              key={page.key}
              style={index === 0 ? { marginTop: 0 } : {}}
              hidden={!isAllowed(getMenuRole(page.pageId))}
              onClick={() => {
                history.push(PREFIX_PATH + page.path);
              }}
            >
              <React.Fragment children={page.icon} />
              <span>{page.title}</span>
            </Menu.Item>
          )
      )}
    </Menu>
  );
};

export default MenuList;
