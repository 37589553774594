import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  FormInstance,
  Input,
  Menu,
  Row,
  Space,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import {
  ColumnHeightOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { RolesItem } from "../Permission/components/data";

interface CustomTableProps extends TableProps<any> {
  toolbar: { onRefresh: () => void };
  leftToolbarRender?: () => (React.ReactChildren | React.ReactChild)[];
  toolbarRender?: () => (React.ReactChildren | React.ReactChild)[];
}
const CustomTable = ({
  toolbar,
  leftToolbarRender,
  toolbarRender,
  ...rest
}: CustomTableProps) => {
  /**
   * Table Size
   */
  const [size, setSize] = useState<SizeType>("middle");
  const sizeSelector = (
    <Menu
      onClick={({ key }) => {
        setSize(key as SizeType);
      }}
    >
      <Menu.Item key="large">넓게</Menu.Item>
      <Menu.Item key="middle">중간</Menu.Item>
      <Menu.Item key="small">좁게</Menu.Item>
    </Menu>
  );

  const showTotal = (total: string | number) => {
    return `총 ${total}건`;
  };

  return (
    <>
      {/* Toolbar */}
      <Row style={{ marginBottom: 16 }}>
        <Col span={12} style={{ textAlign: "left" }}>
          {leftToolbarRender && leftToolbarRender()}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          {toolbarRender && toolbarRender()}
          <Tooltip title="새로고침">
            <Button
              shape="circle"
              icon={<ReloadOutlined />}
              style={{ borderWidth: 0 }}
              onClick={() => toolbar.onRefresh()}
            />
          </Tooltip>
          <Tooltip title="사이즈">
            <Dropdown overlay={sizeSelector} trigger={["click"]}>
              <Button
                shape="circle"
                icon={<ColumnHeightOutlined />}
                style={{ borderWidth: 0 }}
              />
            </Dropdown>
          </Tooltip>
        </Col>
      </Row>
      <Table
        {...rest}
        size={size}
        pagination={{ ...rest.pagination, showTotal }}
      />
    </>
  );
};

export const getColumnSearchProps = (
  dataIndex: string | number | boolean,
  title: string,
  searchInput: Input | null,
  searchText: string | number | boolean,
  searchedColumn: string | number | boolean,
  setSearchInput: React.Dispatch<React.SetStateAction<Input | null>>,
  setSearchText: React.Dispatch<
    React.SetStateAction<string | number | boolean>
  >,
  setSearchedColumn: React.Dispatch<
    React.SetStateAction<string | number | boolean>
  >,
  handleSearch: (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => void,
  handleReset: (clearFilters: (() => void) | undefined) => void,
  withoutRender?: boolean
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          setSearchInput(node);
        }}
        placeholder={`${title} 검색`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          검색
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          icon={<ReloadOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          초기화
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value: string | number | boolean, record: any) =>
    record[dataIndex as string]
      ? record[dataIndex as string]
          .toString()
          .toLowerCase()
          .includes(String(value).toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => searchInput?.select(), 100);
    }
  },
  ...(withoutRender
    ? null
    : {
        render: (text: string | number | boolean) =>
          searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
              searchWords={[searchText as string]}
              autoEscape
              textToHighlight={text ? String(text) : ""}
            />
          ) : (
            text
          ),
      }),
});

/**
 * Editable Table Settings
 */
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}
export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof RolesItem;
  record: RolesItem;
  handleSave: (record: RolesItem) => void;
}
export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          placeholder={title as string}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24, minHeight: 30 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export default CustomTable;
