export default {
  apiKey: "AIzaSyAGIcm9eKUYfR_RW-CbvBFayZiExtdPJsA",
  authDomain: "rnminimart-c0fc7.firebaseapp.com",
  databaseURL: "https://rnminimart-c0fc7-default-rtdb.firebaseio.com",
  projectId: "rnminimart-c0fc7",
  storageBucket: "rnminimart-c0fc7.appspot.com",
  messagingSenderId: "668673490158",
  appId: "1:668673490158:web:254c1d25673e65cca787ff",
  measurementId: "G-CYQ3MGD975",
};
