import React, { useCallback, useEffect, useState } from "react";
import "./TabMenu.css";
import { Button, Checkbox, Col, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { pageList } from "../../../Routes/components/MenuList";
import { RolesItem } from "./data";
import {
  getRoles,
  getSetting,
  updateSetting,
} from "../../../services/FirebaseService";
import { useDispatch, useSelector } from "react-redux";
import { SET_MENU } from "../../../ducks/authReducer";

interface CustomColumnType extends ColumnType<any> {
  isRole?: boolean;
  role?: string;
}

export type menuItem = { title?: string; key: string; data: string[] };

const preColumns: CustomColumnType[] = [
  {
    title: "메뉴명",
    width: 150,
    fixed: "left",
    dataIndex: "title",
    className: "menu-table-name-column",
  },
];

/**
 * 메뉴별로 권한을 지정해주는 페이지
 *
 * - firestore 에 저장이 되는 방식
 * - 1. Collection 이름 : Settings
 * @constructor
 */
const TabMenus = () => {
  const menuList = pageList;
  const { gMenuData } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();

  /**
   * @ko-KR 권한 목록
   * */
  const [roles, setRoles] = useState<RolesItem[]>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);
  /**
   * @ko-KR 내용이 수정이 되었는지 여부
   * */
  const [diff, setDiff] = useState(false);

  const [menuData, setMenuData] = useState<menuItem[]>();

  const getReduxMenuRole = (pageId: string) => {
    if (!gMenuData) return [];
    return gMenuData.find((m: menuItem) => m.key === pageId)?.data;
  };

  useEffect(() => {
    if (diff) return;

    let menuData: menuItem[] = [];
    menuData = menuList.map((menu) => ({
      title: menu.title,
      key: menu.pageId,
      data: getReduxMenuRole(menu.pageId),
    }));

    getMenuSetting(menuData).then((res) => {
      console.log("getMenuSetting", res);
      setMenuData(res);
      setTableLoader(false);
    });
  }, [menuList, diff]);

  const getMenuSetting = async (vMenuData: menuItem[]) => {
    const menuSettings = await getSetting("menu-permission");

    const combinedMenuData = vMenuData?.map((menu) => {
      const setting = menuSettings.find((m: menuItem) => m.key === menu.key);
      if (setting) {
        // 설정 내용과 병합
        const data = menu.data
          ? Array.from(new Set([...setting.data, ...menu.data]))
          : setting.data;
        return { title: menu.title, key: menu.key, data: data || [] };
      }

      return { ...menu, data: menu.data || [] };
    });

    return combinedMenuData || vMenuData;
  };

  /**
   * @ko-KR 카테고리 목록을 가져오는 함수
   */
  const getRoleList = async () => {
    // API
    const roles = await getRoles();

    setRoles(roles);
  };

  // getRoles callback 함수
  const roleListCall = useCallback(async () => await getRoleList(), []);

  useEffect(() => {
    roleListCall();
  }, [roleListCall]);

  // 권한별 컬럼 추가
  const getRoleColumns = () => {
    if (!roles || roles.length === 0) return;

    // columns 에 들어있는 권한 목록 가져오기
    const existRoles = preColumns.filter((r) => r.isRole).map((r) => r.role);
    // 존재하지 않는 것들만 골라서
    const unExistRoles: CustomColumnType[] = roles
      .filter((r) => existRoles.indexOf(r.role) === -1)
      .map((r) => ({
        title: r.title,
        render: (_: string, menu: any) => {
          const currentMenu = menuData?.find((m) => m.key === menu.key);
          if (!currentMenu) return "-";

          return (
            <div
              className={"menu-table-cell"}
              onClick={() => {
                handleCheck(menu, r);
              }}
            >
              <Checkbox
                defaultChecked={menu.data.indexOf(r.role) > -1}
                checked={currentMenu.data.indexOf(r.role) > -1}
              />
            </div>
          );
        },
        isRole: true,
        role: r.role,
        align: "center",
      }));

    return [...preColumns, ...unExistRoles];
  };

  const handleCheck = (menu: any, role: CustomColumnType) => {
    if (!menuData) return false;
    if (!diff) setDiff(true);

    const newMenuData = menuData.map((m) => {
      if (m.key === menu.key) {
        const data: string[] =
          m.data.indexOf(role.role as string) > -1
            ? m.data.filter((d) => d !== role.role)
            : [...m.data, role.role as string];
        return { ...m, data };
      }

      return m;
    });

    setMenuData(newMenuData);
  };

  return (
    <>
      {/* Toolbar */}
      <Row style={{ marginBottom: 16 }}>
        <Col span={12} />
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            type={"primary"}
            key={"save-button"}
            style={{
              borderWidth: 0,
              color: "#FFF",
            }}
            onClick={async () => {
              setTableLoader(true);
              await updateSetting("menu-permission", { data: menuData });
              // 사용자 권한 지정해주기
              dispatch({ type: SET_MENU, gMenuData: menuData });
              setDiff(false);
            }}
            disabled={!diff}
          >
            저장
          </Button>
        </Col>
      </Row>
      {menuData && (
        <Table
          columns={getRoleColumns()}
          dataSource={menuData}
          bordered
          size={"middle"}
          loading={tableLoader}
          rowClassName={"menu-table-row"}
        />
      )}
    </>
  );
};

export default TabMenus;
