import React, { ReactChild, ReactChildren, useEffect, useState } from "react";
import "./Home.css";
import { useSelector } from "react-redux";
import {
  Link,
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import firebase from "firebase/app";

import { Avatar, Col, Dropdown, Layout, Menu, message, Row } from "antd";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";

import logo from "../assets/memart-logo.svg";
import MenuList, { pageList, PREFIX_PATH } from "./components/MenuList";
import PrivateRoute from "./components/PrivateRoutes";
import ErrorPage from "../pages/components/ErrorPage";
import { menuItem } from "../pages/Permission/components/TabMenus";

const { Header, Sider } = Layout;

const Home: React.FC = () => {
  const { photoURL, displayName } = useSelector(
    (state: any) => state.firebase.auth
  );
  const { url } = useRouteMatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const dropdownMenu = (
    <Menu>
      <Menu.Item
        key="profile"
        onClick={() => history.push(PREFIX_PATH + "/profile")}
      >
        <UserOutlined /> 프로필
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="sign-out" onClick={() => firebase.auth().signOut()}>
        <LogoutOutlined /> 로그아웃
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    message.info(`${displayName} 님 환영합니다 🎉`, 1.5);
  }, [displayName]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme={"light"}
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ zIndex: 100 }}
        className="site-layout-sider"
      >
        {/*<div className="logo" />*/}
        <Link className="app-logo" to="/index">
          <img src={logo} alt="logo" height="48" />
        </Link>
        <MenuList />
      </Sider>
      <Layout className="site-layout">
        {/* Header */}
        <Header
          className="site-layout-background site-layout-header"
          style={{ padding: 0, zIndex: 20 }}
        >
          <Row>
            <Col flex={1} span={12} style={{ textAlign: "left" }}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => {
                    setCollapsed(!collapsed);
                  },
                }
              )}
            </Col>
            <Col span={12} style={{ textAlign: "right", paddingRight: 30 }}>
              <Dropdown overlay={dropdownMenu} placement="bottomRight" arrow>
                <a
                  href="#!"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <Avatar
                    style={{ backgroundColor: "#f56a00", marginRight: 8 }}
                    size="small"
                    src={photoURL ? photoURL : ""}
                  >
                    {!photoURL && displayName.substr(0, 1)}
                  </Avatar>
                  <span style={{ color: "#333", verticalAlign: "middle" }}>
                    {displayName}
                  </span>
                </a>
              </Dropdown>
            </Col>
          </Row>
        </Header>

        <Switch>
          <PrivateRoute path={`${url}/:pageId`}>
            <ChildrenPage />
          </PrivateRoute>
          <PrivateRoute path={url}>
            <Redirect
              to={{
                pathname: `${url}/dashboard`,
              }}
            />
          </PrivateRoute>
        </Switch>
      </Layout>
    </Layout>
  );
};

const ChildrenPage = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { role, gMenuData } = useSelector((state: any) => state.authReducer);
  const currentPath = window.location.pathname;

  const routes: {
    pageId: string;
    component: ReactChild | ReactChildren;
  }[] = pageList.map((p) => {
    const { pageId, component } = p;
    return { pageId, component };
  });

  const getReduxMenuRole = (pageId: string) => {
    if (!gMenuData) return [];
    return gMenuData.find((m: menuItem) => m.key === pageId)?.data;
  };

  const getRoleByPath = () => {
    const pathExist = pageList.find((p) => currentPath.indexOf(p.path) > -1);
    return pathExist && getReduxMenuRole(pathExist.pageId)?.indexOf(role) > -1;
  };

  const getChildren = () => {
    /**
     * 3가지 타입이 존재합니다.
     * - 정상적인 child 화면
     * - 접근권한이 없는 화면
     * - 페이지가 존재하지 않는 화면
     */
    const pageData = routes.find((r) => r.pageId === pageId);

    // 페이지가 존재하지 않는 경우,
    if (!pageData) return <ErrorPage errorCode={"404"} />;
    // 접근권한이 없을 경우,
    else if (!getRoleByPath()) return <ErrorPage errorCode={"401"} />;
    // 정상적인 child 화면
    else if (pageData) return pageData.component;

    // default
    return <ErrorPage errorCode={"404"} />;
  };

  return <>{pageId && <React.Fragment children={getChildren()} />}</>;
};

export default Home;
