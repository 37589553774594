import React, { useCallback, useEffect, useState } from "react";
import CustomLayout, { CustomContent } from "../components/CustomLayout";
import { Button, Input, message } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { ColumnType } from "antd/es/table";
import { FileExcelOutlined } from "@ant-design/icons";

import { ExcelHeaderItem, UserListItem } from "./data";
import { getUsers, updateUser } from "../../services/FirebaseService";
import {
  deepcopy,
  exportDataToXlsx,
  formatPhoneNumber,
} from "../../services/Util";
import CustomTable, { getColumnSearchProps } from "../components/CustomTable";
import { FilterConfirmProps } from "antd/es/table/interface";
import UserDetailForm from "./components/UserDetailForm";

const UserPage: React.FC = () => {
  /**
   * @ko-KR ME마트 사용자 목록
   * */
  const [userList, setUserList] = useState<UserListItem[]>();
  // Searched Data
  const [filteredUserList, setFilteredUserList] = useState<UserListItem[]>();

  /**
   * @ko-KR Modal에 전달할 사용자 데이터
   * */
  const [currentRow, setCurrentRow] = useState<UserListItem>();

  /**
   * @ko-KR Table Loader
   * */
  const [tableLoader, setTableLoader] = useState(true);

  /**
   * @ko-KR 사용자 목록을 가져오는 함수
   */
  const getUserList = async () => {
    // API
    const users = await getUsers();

    const userList = users.map((user: UserListItem) => ({
      ...user,
      display_birth: user.user_birth || "-",
      display_phone: formatPhoneNumber(user.user_phone) || "-",
    }));

    setUserList(userList);
    setTableLoader(false);
  };

  // getCategories callback 함수
  const userListCall = useCallback(async () => await getUserList(), []);

  useEffect(() => {
    userListCall();
  }, [userListCall]);

  /**
   * @ko-KR 검색 관련 설정
   */
  const [searchInput, setSearchInput] = useState<Input | null>(null);
  const [searchText, setSearchText] = useState<string | number | boolean>("");
  const [searchedColumn, setSearchedColumn] = useState<
    string | number | boolean
  >("");

  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps | undefined) => void,
    dataIndex: string | number | boolean
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: (() => void) | undefined) => {
    clearFilters && clearFilters();
    setSearchText("");
  };

  /**
   * @ko-KR Table에서 사용할 컬럼 정보
   * */
  const columns: ColumnsType<UserListItem> = [
    {
      title: "이름",
      dataIndex: "user_name",
    },
    {
      title: "회원번호",
      dataIndex: "user_no",
      sorter: {
        compare: (a: UserListItem, b: UserListItem) => a.user_no - b.user_no,
      },
    },
    {
      title: "생년월일",
      dataIndex: "display_birth",
    },
    {
      title: "휴대폰 번호",
      dataIndex: "display_phone",
    },
    {
      title: "",
      width: 100,
      render: (text, row, index) => (
        <a
          onClick={() => {
            setCurrentRow(row);
            setVisible(true);
            // handleUpdateModalVisible(true);
          }}
        >
          상세보기
        </a>
      ),
    },
  ];

  /**
   * @ko-KR Table 컬럼별 검색기능 추가
   * */
  const getFilteredColumn = () => {
    return columns.map((c: ColumnType<UserListItem>) => {
      const columnSearch =
        c.title && c.dataIndex
          ? getColumnSearchProps(
              c.dataIndex as string,
              c.title as string,
              searchInput,
              searchText,
              searchedColumn,
              setSearchInput,
              setSearchText,
              setSearchedColumn,
              handleSearch,
              handleReset
            )
          : {};
      return { ...c, ...columnSearch };
    });
  };

  /**
   * @ko-KR Refresh Table Data
   * */
  const refreshTable = async () => {
    setTableLoader(true);
    await getUserList();
  };

  /**
   * @ko-KR Excel 다운로드 시, 사용하는 Data
   * */
  const getExcelHeader: ExcelHeaderItem[] = deepcopy(columns)
    .map((col: ColumnType<UserListItem>) => {
      return { title: col.title, dataIndex: col.dataIndex || "" };
    })
    .filter(
      (col: ColumnType<UserListItem>) =>
        col.title !== "" && col.dataIndex !== ""
    );

  const getExcelData = () => {
    if (!userList || userList.length === 0) return false;

    const targetData = filteredUserList || userList;

    return targetData.map((user) => {
      const { key, id, user_phone, ...rest } = user;
      // 전화번호 가공 {3-4-4}
      const phoneNumber = formatPhoneNumber(user_phone);
      return { ...rest, user_phone: phoneNumber };
    });
  };

  /**
   * @ko-KR 사용자 상세정보 모달
   */
  const [visible, setVisible] = useState(false);
  const handleDrawer = (visible: boolean, values?: UserListItem) => {
    if (values) {
      updateUser(currentRow?.id!, values).then(() => {
        setVisible(visible);
        refreshTable();
        message.success("수정완료", 1.5);
      });
    } else setVisible(visible);
  };

  return (
    <CustomLayout>
      <CustomContent>
        <CustomTable
          columns={getFilteredColumn()}
          dataSource={userList}
          loading={tableLoader}
          pagination={{ pageSize: 20 }}
          rowKey="key"
          toolbar={{
            onRefresh: () => refreshTable(),
          }}
          toolbarRender={() => [
            <Button
              type={"primary"}
              key={"excel-button"}
              style={{
                borderWidth: 0,
                backgroundColor: "#217044",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => {
                const excelData = getExcelData();
                if (excelData)
                  exportDataToXlsx(
                    getExcelHeader,
                    excelData,
                    "ME마트 사용자 목록",
                    "사용자 목록",
                    [10, 10, 10, 15]
                  );
              }}
              disabled={!userList}
            >
              <FileExcelOutlined /> 엑셀로 내보내기
            </Button>,
          ]}
          onChange={(
            pagination: any,
            filters: any,
            sorter: any,
            extra: { currentDataSource: UserListItem[] }
          ) => {
            if (extra.currentDataSource) {
              setFilteredUserList(extra.currentDataSource);
            }
          }}
        />
      </CustomContent>

      <UserDetailForm
        visible={visible}
        onClose={() => handleDrawer(false)}
        currentUser={currentRow}
        handleDrawer={handleDrawer}
      />
    </CustomLayout>
  );
};

export default UserPage;
